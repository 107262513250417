<div class="page-content">
  <div class="container-fluid">

    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right page-title-right2">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item"><a href="javascript: void(0);" [routerLink]="['/admin/courses']">Courses</a>
              </li>
              <li class="breadcrumb-item active">Chapters</li>
            </ol>


          </div>
          <div class="page-title-right2">
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light" data-toggle="modal"
              data-target=".add_detail">Add</button>
            <app-chaptermodal [courseid]="courseid"></app-chaptermodal>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-3">
            <div class="table-responsive">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <!-- <div class="dataTables_length"><label>Show <select
                                                class="custom-select custom-select-sm form-control form-control-sm"
                                                aria-controls="DataTables_Table_0">
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                            </select> entries</label></div> -->
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_filter">
                    <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
                      data-toggle="modal" data-target=".changeOrder_detail">Change Order</button><label
                      class="ml-2 mt-2">Search:
                      <input type="text" class="form-control form-control-sm" placeholder="Search"
                        [(ngModel)]="searchText"></label>
                  </div>
                </div>
              </div>
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 20px;">
                      No.
                    </th>
                    <th style="width: 140px;">Chapter Picture</th>
                    <th>Name</th>
                    <!-- <th>Description</th> -->
                    <th>Assessment</th>
                    <th>Resources</th>
                    <th>Draft / Published</th>
                    <th style="width: 120px;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr [hidden]="chapterList?.length">
                    <th colspan="7">
                      <h6 class=" mb-0" style="color: #545a60;">No chapters added</h6>
                    </th>
                  </tr>
                  <tr
                    *ngFor="let list of chapterList | paginate: { itemsPerPage: 10, currentPage: p } | filter: searchText; let i = index">
                    <td data-title="N0.">
                      <!-- {{i+1}} -->
                      {{ 10 * (p - 1) + i + 1 }}
                    </td>
                    <td data-title="Chapter Picture">
                      <div class="courses-bg" style="background-image:url({{list.image}})"></div>
                      <!-- <img src="{{list.image}}" alt="" style="width:100px"> -->
                    </td>

                    <td data-title="Name">{{list.chapterName}}</td>
                    <!-- <td class="description-td" data-title="Description">
                                            <div class="description-text" [innerHtml]="list.description"></div>
                                        </td> -->
                    <td data-title="Assessment" *ngIf="list.assessmentCount == '0';else elseBlock">
                      No </td>
                    <ng-template #elseBlock>
                      <td data-title="Assessment">Yes</td>
                    </ng-template>
                    <td data-title="Resources">
                      {{list.resourceCount?list.resourceCount:'NA'}}
                    </td>
                    <td data-title="Draft / Published">
                      <label class="switch">
                        <!-- <input type="checkbox" id="verify" value="1" checked=""> -->
                        <input type="checkbox" id="verify" value="1" *ngIf="list.status == 'Active'" [checked]="true"
                          (click)="ChangeUserStatus($event,list._id)">
                        <input type="checkbox" id="verify" value="1" *ngIf="list.status == 'Inactive'" [checked]="false"
                          (click)="ChangeUserStatus($event,list._id)">

                        <span class="slider round"></span>
                      </label>
                    </td>
                    <td data-title="Actions">
                      <a href="javascript:void(0)" (click)="ChapterDetail(list)" class="mr-2 text-solitud"
                        data-toggle="tooltip" data-placement="top" title="" data-original-title="View"><i
                          class="fas fa-eye font-size-18"></i></a>
                      <a href="javascript:void(0);" class="text-danger" data-toggle="modal" data-placement="top"
                        title="" data-original-title="Delete" data-target="#confirmodal"
                        (click)="DeleteChapter(list._id, 'open')"><i class="mdi mdi-trash-can font-size-18"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-sm-12 col-md-5">
                  <!-- <div class="dataTables_info">Showing 1 to 10 of 11 entries</div> -->
                </div>
                <div class="col-sm-12 col-md-7">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <!-- <ul class="pagination pagination-rounded">
                                            <li class="paginate_button page-item previous disabled"><a
                                                    href="javascript:void(0)" class="page-link"><i
                                                        class="mdi mdi-chevron-left"></i></a></li>
                                            <li class="paginate_button page-item active"><a href="javascript:void(0)"
                                                    class="page-link">1</a></li>
                                            <li class="paginate_button page-item "><a href="javascript:void(0)"
                                                    class="page-link">2</a>
                                            </li>
                                            <li class="paginate_button page-item next"><a href="javascript:void(0)"
                                                    class="page-link"><i class="mdi mdi-chevron-right"></i></a></li>
                                        </ul> -->
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

  </div> <!-- container-fluid -->
</div>
<!-- End Page-content -->

<div class="modal fade alert_popup" tabindex="-1" id="confirmodal" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
          <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
            <div class="alert-head sc-ion-alert-ios">
              <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete
                Chapter</h2>
            </div>
            <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you
              sure you want to delete?</div>
            <div class="alert-button-group sc-ion-alert-ios">
              <button type="button" class="alert-button"><span class="alert-button-inner sc-ion-alert-ios"
                  (click)="DeleteChapter(chapterid,'confirm')">Yes</span>
              </button>
              <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
                  class="alert-button-inner sc-ion-alert-ios">No</span></button>
            </div>
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>



<div class="modal fade changeOrder_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollables">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title mt-0">Change Order List</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body modal-orderList">
        <div #scrollEl cdk-scrollable style="height:400px; overflow-y:scroll">
          <table class="table mb-0">
            <thead class="thead-light">
              <tr>
                <th style="width: 20px;">No.</th>
                <th style="width: 100px;">Chapter Picture </th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody class="box-list" cdkDropList cdkDropListOrientation="vertical" (cdkDropListDropped)="drop($event)">
              <tr class="drag-box" *ngFor="let list of chapterList; let i = index" cdkDrag>
                <td>{{i+1}}</td>
                <td>
                  <!-- <img src="{{list.image}}" alt="" style="width: 50px; height: 50px;"> -->
                  <div class="courses-bg" style="background-image:url({{list.image}})"></div>
                </td>
                <td>{{list.chapterName}}</td>
              </tr>
            </tbody>
            <!-- <pre>{{newArr | json}}</pre> -->
          </table>
        </div>
        <button type="submit" class="btn btn-warning" (click)="ChangeOrder()">Save</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->