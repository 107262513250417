<div class="modal fade" id="add_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mt-0">Add Course</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="AddCourseForm" (ngSubmit)="AddCourse()">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="control-label">Upload Picture</label>
                                <div class="custom_file">
                                    <input type="file" class="custom_file_input" formControlName="CourseImage"
                                        id="customFile" (change)="handleFileInput($event.target.files)">
                                    <div *ngIf="submitted && f.CourseImage.errors" class="invalid-feedback"
                                        style="display: block;">
                                        <div *ngIf="f.CourseImage.errors.required">CourseImage is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="control-label">Name</label>
                                <input type="text" class="form-control" formControlName="Coursename" name="Coursename"
                                    id="Coursename">
                                <div *ngIf="submitted && f.Coursename.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.Coursename.errors.required">Coursename is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="control-label">Instructor Name</label>
                                <input type="text" class="form-control" formControlName="instructor" name="Coursename"
                                    id="Coursename">
                                <div *ngIf="submitted && f.instructor.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.instructor.errors.required">Instructor is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="control-label">Description</label>

                                <!-- <ckeditor [editor]="editor" formControlName="Coursedescription"></ckeditor> -->
                                <angular-editor formControlName="Coursedescription">
                                </angular-editor>

                                <div *ngIf="submitted && f.Coursedescription.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.Coursedescription.errors.required">Coursedescription is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-warning" type="submit">Save
                        Detail</button>
                </form>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->