import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChapterserviceService } from 'src/app/Services/chapterservice/chapterservice.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { EventsService } from 'src/app/Services/core/events/events.service';
declare var $: any;
@Component({
  selector: 'app-add-question-modal',
  templateUrl: './add-question-modal.component.html',
  styleUrls: ['./add-question-modal.component.scss']
})
export class AddQuestionModalComponent implements OnInit {
  public AddmoreQuestionForm: FormGroup;
  submitted = false;
  @Input() chapterid: any;
  constructor(
    private fb: FormBuilder,
    private chapterservice: ChapterserviceService,
    private commonService: CommonService,
    private events: EventsService,

  ) { }

  ngOnInit(): void {


    this.AddmoreQuestionForm = this.fb.group({
      question: ['', Validators.required],
      options: this.fb.array([
        this.createFormrGroupValue(),
      ])
    });
  }
  get f() { return this.AddmoreQuestionForm.controls; }


  get FormArrayvalue() {
    return (<FormArray>this.AddmoreQuestionForm.get('options'));
  }

  createFormrGroupValue() {
    return this.fb.group({
      answer: this.fb.control(''),
      optradio: this.fb.control(''),

    });
  }

  addAnswer() {

    // this.createFormrGroupValue();
    this.FormArrayvalue.push(this.createFormrGroupValue());
  }


  CorrectAns: any;
  ChooseCorrectAns(ans) {
    //  
    // console.log(a)
    this.CorrectAns = ans;
  }
  // options: any = [];
  items: any = [];
  SaveQuestion() {

    this.submitted = true;
    this.createFormrGroupValue();

    if (this.AddmoreQuestionForm.invalid) {
      return
    }
    this.commonService.presentSpinner();
    for (var i = 0; i < this.FormArrayvalue.value.length; i++) {
      if (this.FormArrayvalue.value[i].answer == '') {
        this.commonService.dismissSpinner();
        this.commonService.presentErrorToast('', 'Answer feild(s) are required');
        this.items = [];
        return
      }
      var obj = { optionNumber: '', optionValue: '' };
      var arrIndex;
      // this.items[i + 1] = this.FormArrayvalue.value[i].answer;
      arrIndex = i + 1
      obj.optionNumber = arrIndex.toString();
      obj.optionValue = this.FormArrayvalue.value[i].answer;
      this.items.push(obj);

      if (this.CorrectAns != null && this.CorrectAns == this.FormArrayvalue.value[i].optradio) {
        // if (this.FormArrayvalue.value[i].optradio != null) {
        var correctAnswer = this.FormArrayvalue.value[i].optradio;
      }

    }
    console.log(this.items)

    let postdata = {
      'chapterId': this.chapterid,
      'question': this.AddmoreQuestionForm.value.question,
      'options': this.items,
      'correctAnswer': correctAnswer
    }
    this.chapterservice.Addassessment(postdata).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code === 200) {
            this.commonService.dismissSpinner();
            this.AddmoreQuestionForm.reset();
            this.items = [];
            this.submitted = false;
            this.AddmoreQuestionForm.get('options')['controls'] = [];
            this.createFormrGroupValue()
            $('.add_detail').modal('hide');
            this.commonService.presentSuccessToast('', res.message);

          }
        } else {
          this.items = [];
        }
      }
    )
    console.log(postdata);
  }
  removefeilds(index) {
    this.FormArrayvalue.removeAt(index);
    console.log(this.AddmoreQuestionForm.value);
  }

}
