<div class="modal fade add_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mt-0">Add Chapter </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="AddChapterForm" (ngSubmit)="AddChapter()">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="control-label">Upload Picture</label>
                                <div class="custom_file">
                                    <input type="file" class="custom_file_input" formControlName="ChapterImage"
                                        id="ChapterImage" (change)="handleFileInput($event.target.files)">
                                    <div *ngIf="submitted && f.ChapterImage.errors" class="invalid-feedback"
                                        style="display: block;">
                                        <div *ngIf="f.ChapterImage.errors.required">ChapterImage is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="control-label">ChapterName</label>
                                <input type="text" class="form-control" formControlName="chapterName" name="chapterName"
                                    id="chapterName">
                                <div *ngIf="submitted && f.chapterName.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.chapterName.errors.required">ChapterName is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="control-label">Description</label>
                                <!-- <div class="summernote">Hello Summernote</div> -->
                                <angular-editor formControlName="Chapterdescription">
                                </angular-editor>
                                <div *ngIf="submitted && f.Chapterdescription.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.Chapterdescription.errors.required">Chapter description is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-warning" type="submit">Save
                        Detail</button>
                </form>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->