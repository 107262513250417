<div class="page-content">
  <div class="container-fluid">

    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Subscribers</li>
            </ol>
          </div>
          <div class="page-title-right2">
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light" data-toggle="modal"
              data-target="#add_detail">Add</button>
            <app-usermodal></app-usermodal>

            <!-- /.modal -->

          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-3">
            <div class="table-responsive">
              <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_filter"><label>Search:
                      <input type="text" class="form-control form-control-sm" placeholder="Search"
                        [(ngModel)]="searchText" (input)="applyFilter($event.target.value)"></label>
                  </div>
                </div>
              </div>
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th>No.</th>
                    <th>Picture</th>
                    <th>Name</th>
                    <th>Email ID</th>
                    <th>No. of Course</th>
                    <th>Active / Inactive</th>
                    <th style="width: 132px;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr [hidden]="UserList?.length">
                    <th colspan="7">
                      <h6 class=" mb-0" style="color: #545a60;">No Users added</h6>
                    </th>
                  </tr>
                  <tr
                    *ngFor="let list of UserList | paginate: { id:'userlist',itemsPerPage: limit, currentPage: page,totalItems: count } ; let i = index">
                    <td data-title="No.">
                      <!-- | filter: searchText; -->
                      <!-- {{i+1}} -->
                      {{ limit * (page - 1) + i + 1 }}
                    </td>

                    <td data-title="Picture">
                      <div class="user_img"
                        style="background-color: #f5f5f5; background-image:url({{list.profileImage}})"></div>
                      <!-- <img src="{{list.profileImage}}" alt="" height="30" class="img  border-radius-50"> -->
                    </td>
                    <td data-title="Name">
                      {{list.name}}
                    </td>
                    <td data-title="Email ID">{{list.email}}</td>

                    <td data-title="No. of Course">
                      {{list.assignedCourseCount}}
                    </td>
                    <td data-title="Active / Inactive">
                      <label class="switch">
                        <!-- <input type="checkbox" id="verify" value="1" checked=""> -->
                        <input type="checkbox" id="verify" value="1" *ngIf="list.status == 'Active'" [checked]="true"
                          (click)="ChangeUserStatus($event,list._id)">
                        <input type="checkbox" id="verify" value="1" *ngIf="list.status == 'Inactive'" [checked]="false"
                          (click)="ChangeUserStatus($event,list._id)">
                        <span class="slider round"></span>
                      </label>
                    </td>
                    <td data-title="Action">
                      <a href="javascript:void(0);" (click)="AsignCourseDetails(list._id)" class="mr-2 text-solitud"
                        data-toggle="tooltip" data-placement="top" title="" data-original-title="View"><i
                          class="fas fa-eye font-size-18"></i></a>
                      <button type="button" class="mr-2 text-primary btn_style" data-toggle="modal"
                        data-target=".edit_detail" (click)="EditUser(list)"><i
                          class="mdi mdi-pencil font-size-18"></i></button>
                      <button type="button" class="mr-2 text-primary btn_style" data-toggle="modal"
                        data-target="#changepass" (click)="changePassword(list, 'open')"><i
                          class="mdi mdi-account-key font-size-18">
                        </i></button>
                      <a href="javascript:void(0);" class="text-danger" data-toggle="modal" data-placement="top"
                        data-target="#confirmodal" title="" data-original-title="Delete"
                        (click)="openUsermodal(list._id)"><i class="mdi mdi-trash-can font-size-18"></i></a>


                      <div class="modal fade alert_popup" tabindex="-1" id="confirmodal" role="dialog"
                        aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static">
                        <div class="modal-dialog modal-dialog-centered">
                          <div class="modal-content">
                            <div class="modal-body">

                              <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
                                <!-- <div class="sc-ion-alert-ios-backdrop"></div> -->
                                <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
                                  <div class="alert-head sc-ion-alert-ios">
                                    <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete
                                      User</h2>
                                  </div>
                                  <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you
                                    sure you want to delete?</div>
                                  <div class="alert-button-group sc-ion-alert-ios">
                                    <button type="button" class="alert-button"><span
                                        class="alert-button-inner sc-ion-alert-ios"
                                        (click)="DeleteUser(list._id)">Yes</span>
                                    </button>
                                    <button type="button" class="alert-button" tabindex="0" data-dismiss="modal"
                                      aria-label="Close"><span
                                        class="alert-button-inner sc-ion-alert-ios">No</span></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div><!-- /.modal-content -->
                        </div><!-- /.modal-dialog -->
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info">Showing {{pageData}} of {{count}} entries</div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <!-- <div class="dataTables_paginate paging_simple_numbers">
                                        <ul class="pagination pagination-rounded">
                                            <li class="paginate_button page-item previous disabled"><a href="#"
                                                    class="page-link"><i class="mdi mdi-chevron-left"></i></a></li>
                                            <li class="paginate_button page-item active"><a href="#"
                                                    class="page-link">1</a></li>
                                            <li class="paginate_button page-item "><a href="#" class="page-link">2</a>
                                            </li>
                                            <li class="paginate_button page-item next"><a href="#" class="page-link"><i
                                                        class="mdi mdi-chevron-right"></i></a></li>
                                        </ul>
                                    </div> -->
                  <pagination-controls id="userlist" responsive="true" previousLabel="Prev" nextLabel="Next"
                    (pageChange)="onTableDataChange($event)">
                  </pagination-controls>
                </div>
              </div>
              <div class="modal fade edit_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title mt-0">Edit User</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form [formGroup]="EditUserForm" (ngSubmit)="UpdateUser()">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="form-group">
                              <label class="control-label">Upload
                                Picture</label>
                              <div class="custom_file custom_img_input">
                                <input type="file" class="custom_file_input" id="UserImage" formControlName="UserImg"
                                  (change)="handleFileInput($event.target.files)">
                                <div class="user_img"
                                  style="background-color: rgb(245, 245, 245); background-image:url({{img}})"></div>
                                <!-- <img *ngIf="img" src="{{img}}" alt="" style="width: 100px;"> -->
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <div class="form-group">
                              <label for="validationCustom01">Name</label>
                              <input type="text" class="form-control" formControlName="Name"
                                [ngClass]="{ 'is-invalid': submitted && f.Name.errors }">
                              <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
                                <div *ngIf="f.Name.errors.required">Name is required
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <div class="form-group">
                              <label for="validationCustom01">Email</label>
                              <input type="text" class="form-control" formControlName="email"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button class="btn btn-warning" type="submit">Update</button>
                      </form>
                    </div>
                  </div><!-- /.modal-content -->
                </div><!-- /.modal-dialog -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->


  </div> <!-- container-fluid -->
</div>
<div class="modal fade" tabindex="-1" id="changepass" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title mt-0">Change Password</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal" [formGroup]="ChangePasswordForm">

          <div class="form-group auth-form-group-custom mb-4">
            <i class="ri-lock-2-line auti-custom-input-icon"></i>
            <label for="userpassword">New Password</label>
            <input type="password" class="form-control" id="userpassword" placeholder="Enter new password"
              formControlName="NewPassword">
            <div *ngIf="submitted && f1.NewPassword.errors" class="invalid-feedback" style="display: block;">
              <div *ngIf="f1.NewPassword.errors.required">New
                password is required</div>
              <div *ngIf="f1.NewPassword.errors.minlength">
                Password must be at least 6 characters</div>
            </div>
          </div>
          <div class="form-group auth-form-group-custom mb-4">
            <i class="ri-lock-2-line auti-custom-input-icon"></i>
            <label for="userpassword">Confirm Password</label>
            <input type="password" class="form-control" id="userpassword" placeholder="Enter Confirm password"
              formControlName="confirmPassword">
            <div *ngIf="submitted && f1.confirmPassword.errors" class="invalid-feedback" style="display: block;">
              <div *ngIf="f1.confirmPassword.errors.required">
                Confirm password is required</div>
              <div *ngIf="f1.confirmPassword.errors.mustMatch">
                Passwords must match</div>
            </div>
          </div>

          <div class="mt-4">
            <button class="btn btn-primary w-md waves-effect waves-light" type="submit"
              (click)="changePassword(changepasswordid,'confirm')">Save</button>
          </div>
        </form>
      </div>
    </div><!-- /Change use password .modal-content -->
  </div><!-- /.modal-dialog -->
</div>