<div class="modal fade" tabindex="-1" id="add_detail" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mt-0">Add User</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="AddUserForm" (ngSubmit)="SaveUser()">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="control-label">Upload Picture</label>
                                <div class="custom_file">
                                    <input type="file" class="custom_file_input" formControlName="Userimg"
                                        id="customFile" (change)="handleFileInput($event.target.files)">
                                    <div *ngIf="submitted && f.Userimg.errors" class="invalid-feedback"
                                        style="display: block;">
                                        <div *ngIf="f.Userimg.errors.required">UserImage is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">Name</label>
                                <input type="text" class="form-control" formControlName="Name">
                                <div *ngIf="submitted && f.Name.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.Name.errors.required">Name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">Email</label>
                                <input type="email" class="form-control" formControlName="Email"
                                    [ngClass]="{ 'is-invalid': submitted && f.Email.errors }">
                                <div *ngIf="submitted && f.Email.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.Email.errors.required">Email is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="validationCustom01">Password</label>
                                <input type="password" class="form-control" formControlName="Password">
                                <div *ngIf="submitted && f.Password.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.Password.errors.required">Password is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-warning" type="submit">Save</button>
                </form>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div>