import { Injectable } from '@angular/core';
import { endpoint } from '../../constants/endpoints.constants';
import { CommonService } from '../common/common.service';
import { EventsService } from '../core/events/events.service';
import { HttpWrapperService } from '../core/http/http-wrapper/http-wrapper.service';
import { StorageService } from '../core/storage/storage.service';
import { storageKeys } from '../../constants/storage-keys.constants';
import { eventKeys } from '../../constants/event-keys.constants';
import { UserData } from '../../components/admin/modals/usermodal/users.model';
import { Coursedata } from '../../components/admin/modals/coursemodal/course.model';
import { HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ChapterserviceService {
  private subject = new Subject<any>();
  course_id: string
  chapterid: string
  assessmentid: string;
  userid: string;
  constructor(private httpWrapperService: HttpWrapperService,
    private commonService: CommonService,
    private storage: StorageService,
    private events: EventsService) { }

  // sendchaapterobj(item) {
  //   this.subject.next({ data: item });
  // }
  // getchaapterobj(): Observable<any> {
  //   return this.subject.asObservable();
  // }
  async AddChapter(userdata: any) {
    return this.httpWrapperService.post(endpoint.addchapter, userdata).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.events.publish(eventKeys.usercreated, '');
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }

  async getChapterList() {
    return this.httpWrapperService.get(endpoint.getcoursebyid(this.course_id)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.chapterList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 400) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async getChapterById() {

    return this.httpWrapperService.get(endpoint.getchapterbyid(this.chapterid)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.chapterList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 400) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }


  async DeleteChapter(user_id: string) {
    let payload = {}
    return this.httpWrapperService.delete(endpoint.deletechapter(user_id)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }

  async Addassessment(questions: any) {

    return this.httpWrapperService.post(endpoint.addassessment, questions).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.events.publish(eventKeys.usercreated, '');
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }

  async getChapterassesment() {

    return this.httpWrapperService.get(endpoint.singleassessment(this.chapterid)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.chapterData) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 400) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }
  async EditChapterDetail(payload: any) {

    return this.httpWrapperService.put(endpoint.Editchapterbyid(this.chapterid), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.chapterList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 400) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }
  async Editassessment(payload: any) {

    return this.httpWrapperService.put(endpoint.editassesment(this.assessmentid), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      );
  }

  async ChangeOrder(payload: any) {
    debugger
    return this.httpWrapperService.put(endpoint.changeorder(this.course_id), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      );
  }

  async Deleteassessment(id) {
    let payload = {}
    return this.httpWrapperService.delete(endpoint.deleteassessment(id)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }

  async AddVideos(payload: any) {

    return this.httpWrapperService.post(endpoint.addchaptervideo(this.chapterid), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }
  async EditVideo(payload) {

    return this.httpWrapperService.put(endpoint.editvideo(this.chapterid), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      );
  }

  async Addresource(payload: any) {

    return this.httpWrapperService.post(endpoint.addresource(this.chapterid), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }

  getcoursedetail() {
    return this.httpWrapperService.get(endpoint.asigncurselist(this.userid)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.userData) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 400) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async getChapterResource() {

    return this.httpWrapperService.get(endpoint.getresource(this.chapterid)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.resourceList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 400) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async getChaptervideo() {

    return this.httpWrapperService.get(endpoint.getchaptervideo(this.chapterid)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.videoList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 400) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async DeleteResource(id: any) {

    return this.httpWrapperService.delete(endpoint.deleteresource(this.chapterid) + '?resourceId=' + id).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }

  async Deletevideo(id: any) {

    return this.httpWrapperService.delete(endpoint.deletevideo(this.chapterid) + '?videoId=' + id).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }
  async ChangeChapterStatus(payload) {

    return this.httpWrapperService.put(endpoint.chapterchangestatus(this.chapterid), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      );
  }
}
