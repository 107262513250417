import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { CommonService } from 'src/app/Services/common/common.service';
declare var $: any;
@Component({
  selector: 'app-usermodal',
  templateUrl: './usermodal.component.html',
  styleUrls: ['./usermodal.component.scss']
})
export class UsermodalComponent implements OnInit {
  AddUserForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private commonSerivce: CommonService
  ) { }

  ngOnInit(): void {
    this.AddUserForm = this.formBuilder.group({
      Userimg: ['', Validators.required],
      Name: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      Password: ['', Validators.required],
    })
  }

  get f() { return this.AddUserForm.controls; }

  fileToUpload: File = null;
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  SaveUser() {

    this.submitted = true;


    // stop here if form is invalid
    if (this.AddUserForm.invalid) {
      return;
    }

    this.commonSerivce.presentSpinner();
    var frmData = new FormData();
    frmData.append("profileImage", this.fileToUpload);
    frmData.append("name", this.AddUserForm.value.Name);
    frmData.append("email", this.AddUserForm.value.Email);
    frmData.append("password", this.AddUserForm.value.Password);
    // let postdata = {
    //   "name": this.AddUserForm.value.Name,
    //   "email": this.AddUserForm.value.Email,
    //   "password": this.AddUserForm.value.Password
    // }
    this.auth.AddUser(frmData).then(
      (res) => {
        this.commonSerivce.dismissSpinner();
        if (res) {
          if (res.code === 200) {
            this.AddUserForm.reset();
            $('#add_detail').modal('hide');
            this.submitted = false;
            this.commonSerivce.presentSuccessToast('', res.message);

          }
        }
      }
    );

  }



}
