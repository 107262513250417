import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CourseService } from 'src/app/Services/courseservice/course.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ThrowStmt } from '@angular/compiler';
import { EventsService } from 'src/app/Services/core/events/events.service';
import { eventKeys } from 'src/app/constants/event-keys.constants';
declare var $: any;
@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {
  page = 1;
  count = 0;
  tableSize = 7;
  courselist: any = [];
  searchText: string;
  editor = ClassicEditor;
  EditCourseForm: FormGroup;
  submitted = false;
  limit: any;


  constructor(
    private formBuilder: FormBuilder,
    private courseservice: CourseService,
    private commonService: CommonService,
    private router: Router,
    private events: EventsService,
  ) {

    this.events.subscribe(eventKeys.usercreated, () => {
      this.GetCourseList();
    })
  }

  ngOnInit(): void {
    this.EditCourseForm = this.formBuilder.group({
      CourseImage: ['', ''],
      Coursename: ['', Validators.required],
      Coursedescription: ['', Validators.required],
      Instructor: ['', Validators.required],
    })
    this.GetCourseList();
  }
  get f() { return this.EditCourseForm.controls; }

  fileToUpload: File = null;
  handleFileInput(files: FileList) {

    this.fileToUpload = files.item(0);
  }
  onTableDataChange(event) {

    this.page = event;
    this.GetCourseList();
  }
  pageData: any;
  GetCourseList() {

    this.commonService.presentSpinner();
    this.courseservice.getCourseList(this.page).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code == 200) {
            this.courselist = [];
            this.courselist = res.courseList;
            this.count = res.totalResult
            this.limit = res.limit;
            if (this.page == 1) {
              // var abc = this.page + "-" + this.page * 10;
              // this.pageData = this.page + "-" + this.page * 10;
              if (this.count > this.page * 10) {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
              } else {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
              }

            } else {
              if (this.count > this.page * 10) {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
              } else {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
              }
              //   (this.page - 1) * 10 + 1 - this.page * 10
            }

          } else if (res.code == 401) {
            this.router.navigate(['/']);
            localStorage.clear();
          }
        } else {

        }

      }
    )
  }

  course_id: any;
  DeleteCourse(id, data: string) {

    if (data == 'open') {
      this.course_id = id;

      $('#confirmodal').modal('show');
    } else if (data == 'confirm') {
      this.commonService.presentSpinner();
      this.courseservice.deleteCourse(id).then(
        (res: any) => {
          this.commonService.dismissSpinner();
          if (res && res.code === 200) {
            this.GetCourseList();
            $('#confirmodal').modal('hide');
            this.commonService.presentSuccessToast('', res.message)

          }
        }
      )
    }
  }

  courseid: any;
  img: any;
  openeditcoursemodal(items) {

    this.img = '';
    this.fileToUpload = null;
    this.courseid = items._id;
    this.img = items.image
    this.EditCourseForm.patchValue({
      Coursename: items.courseName,
      Coursedescription: items.description,
      Instructor: items.instructor,
    });
  }

  UpdateCourse() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.EditCourseForm.invalid) {
      return;
    }
    var frmData = new FormData();
    frmData.append("image", this.fileToUpload);
    frmData.append("courseName", this.EditCourseForm.value.Coursename);
    frmData.append("description", this.EditCourseForm.value.Coursedescription);
    frmData.append("instructor", this.EditCourseForm.value.Instructor);
    this.courseservice.Couesr_Id = this.courseid;
    this.courseservice.EditCourse(frmData).then(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.commonService.dismissSpinner();

            this.GetCourseList();
            this.EditCourseForm.reset();
            $('.edit_detail').modal('hide');
            this.commonService.presentSuccessToast('', res.message);

          } else {
            this.commonService.dismissSpinner();

          }
        }
      }
    );
  }

  ChangeCurseStatus(event, id) {

    this.commonService.presentSpinner();
    this.courseservice.Couesr_Id = id;
    var data;
    if (event.srcElement.checked == true) {
      data = { "status": "Active" }
    } else {
      data = { "status": "Inactive" }
    }
    this.courseservice.ChangeCourseStatus(data).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          this.commonService.dismissSpinner();
          if (res.code === 200) {
            this.GetCourseList();
            this.commonService.presentSuccessToast('', res.message);

          }
        } else {
          this.commonService.dismissSpinner();
        }
      }
    );

  }

  applyFilter(filterValue) {
    if (filterValue.length >= 3) {
      this.courseservice.CourseSearchFilter(filterValue).then(
        (res) => {
          if (res) {
            if (res.code == 200) {
              this.courselist = [];
              this.courselist = res.courseList;
              this.count = res.totalResult
              this.limit = res.limit;
              if (this.page == 1) {
                if (this.count > this.page * 10) {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
                } else {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
                }

              } else {
                if (this.count > this.page * 10) {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
                } else {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
                }
              }

            } else if (res.code == 401) {
              this.router.navigate(['/']);
              localStorage.clear();
            }
          } else {

          }

        }
      )
    } else {
      this.GetCourseList();
    }
  }
  ChapterList(items) {
    this.router.navigate(['/admin/chapter'], { queryParams: { courseid: items._id } });
  }
}
