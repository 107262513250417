<div class="page-content">
  <div class="container-fluid">

    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item"><a href="javascript: void(0);"
                  [routerLink]="['/admin/subscribers']">Subscribers</a>
              </li>
              <li class="breadcrumb-item active">User</li>
            </ol>
          </div>
          <div class="page-title-right2">
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light" data-toggle="modal"
              data-target=".add_detail">Add Course</button>
            <app-asigncoursemodel [userid]="userid"></app-asigncoursemodel>

          </div>

        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <div class="profile-content">
              <div class="profile-details">
                <div class="user-img">
                  <div class="user_img"
                    style="width:128px; height:128px; background-color: rgb(245, 245, 245); background-image:url({{courselist.profileImage}})">
                  </div>
                  <!-- <img src="{{courselist.profileImage}}" width="128px" height="128px" class="img-circle" alt=""> -->
                </div>
                <h4>{{courselist.name?courselist.name:'NA'}}</h4>
                <div class="custom-control custom-switch mb-2 switch_profile">
                  <!-- <input type="checkbox" id="light-mode-switch" checked="" class="custom-control-input theme-choice"> -->

                  <label for="light-mode-switch" *ngIf="courselist.status == 'Active'">Active </label>
                  <label for="light-mode-switch" *ngIf="courselist.status == 'Inactive'">Inactive</label>
                  <label class="switch ml-3">
                    <!-- <input type="checkbox" id="verify" value="1" checked=""> -->
                    <input type="checkbox" id="verify" value="1" *ngIf="courselist.status == 'Active'" [checked]="true"
                      (click)="ChangeUserStatus($event,courselist._id)">
                    <input type="checkbox" id="verify" value="1" *ngIf="courselist.status == 'Inactive'"
                      [checked]="false" (click)="ChangeUserStatus($event,courselist._id)">
                    <span class="slider round"></span>
                  </label>

                </div>
                <p class="text-muted mb-2">Email Id: {{courselist.email?courselist.email:'NA'}}</p>
                <!-- <p class="text-muted">Phone No. 9354131986</p> -->
                <div class="action_btn">
                  <button type="button" class="mr-2 text-primary btn_style" data-toggle="modal"
                    data-target=".edit_user_detail"><i class="mdi mdi-pencil font-size-18"></i></button>
                  <button type="button" data-toggle="modal" data-target=".changepassuser"
                    class="mr-2 text-primary btn_style"><i class="mdi mdi-account-key font-size-18"></i></button>
                  <a href="javascript:void(0);" class="" data-toggle="tooltip"></a>
                  <button type="button" class="mr-2 text-danger btn_style" data-toggle="modal"
                    data-target=".alert_popup"><i class="mdi mdi-trash-can font-size-18"></i></button>
                </div>
              </div>
            </div>
            <div class="modal fade changepassuser show" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title mt-0">Change Password</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <form class="form-horizontal" [formGroup]="ChangePasswordForm">
                      <div class="form-group auth-form-group-custom mb-4">
                        <i class="ri-lock-2-line auti-custom-input-icon"></i>
                        <label for="userpassword">Password</label>
                        <input type="password" class="form-control" formControlName="NewPassword" id="NewPassword"
                          placeholder="Enter new password">
                        <div *ngIf="submitted && f1.NewPassword.errors" class="invalid-feedback"
                          style="display: block;">
                          <div *ngIf="f1.NewPassword.errors.required">New
                            password is required</div>
                          <div *ngIf="f1.NewPassword.errors.minlength">
                            Password must be at least 6 characters</div>
                        </div>
                      </div>
                      <div class="form-group auth-form-group-custom mb-4">
                        <i class="ri-lock-2-line auti-custom-input-icon"></i>
                        <label for="userpassword">Confirm Password</label>
                        <input type="password" formControlName="confirmPassword" class="form-control"
                          id="confirmPassword" placeholder="Enter Confirm password">
                        <div *ngIf="submitted && f1.confirmPassword.errors" class="invalid-feedback"
                          style="display: block;">
                          <div *ngIf="f1.confirmPassword.errors.required">
                            Confirm password is required</div>
                          <div *ngIf="f1.confirmPassword.errors.mustMatch">
                            Passwords must match</div>
                        </div>
                      </div>

                      <div class="mt-4">
                        <button class="btn btn-primary w-md waves-effect waves-light" type="submit"
                          (click)="UserAction(courselist,'changepassword')">Save</button>
                      </div>
                    </form>
                  </div>
                </div><!-- /.modal-content -->
              </div><!-- /.modal-dialog -->
            </div>
            <div class="modal fade edit_user_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
              style="display: none;" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title mt-0">Edit User</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <form [formGroup]="EditUserInfoForm">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label class="control-label">Upload
                              Picture</label>
                            <div class="custom_file custom_img_input">
                              <input type="file" class="custom_file_input" id="customFile" formControlName="UserImg"
                                (change)="handleFileInput($event.target.files)">
                              <div class="user_img"
                                style="background-color: rgb(245, 245, 245); background-image:url({{courselist.profileImage}})">
                              </div>

                              <!-- <img *ngIf="courselist.profileImage" src="{{courselist.profileImage}}" alt=""
                                style="width: 50px;"> -->
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label for="validationCustom01">Name</label>
                            <input type="text" class="form-control" formControlName="Name">
                            <div *ngIf="submitted && f2.Name.errors" class="invalid-feedback">
                              <div *ngIf="f2.Name.errors.required">Name is required
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label for="validationCustom01">Email</label>
                            <input type="email" class="form-control" formControlName="email">

                          </div>
                        </div>
                      </div>
                      <button class="btn btn-warning" type="submit"
                        (click)="UserAction(courselist,'updateuserinfo')">Save</button>
                    </form>
                  </div>
                </div><!-- /.modal-content -->
              </div><!-- /.modal-dialog -->
            </div>
            <div class="modal fade alert_popup" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
              aria-hidden="true" style="display: none;">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-body">
                    <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
                      <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
                        <div class="alert-head sc-ion-alert-ios">
                          <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete User</h2>
                        </div>
                        <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you sure you want to delete?
                        </div>
                        <div class="alert-button-group sc-ion-alert-ios">
                          <button type="button" class="alert-button" data-dismiss="modal" aria-label="Close"
                            (click)="UserAction(courselist,'deleteuser')"><span
                              class="alert-button-inner sc-ion-alert-ios">Yes</span>
                          </button>
                          <button type="button" class="alert-button" tabindex="0" data-dismiss="modal"
                            aria-label="Close"><span class="alert-button-inner sc-ion-alert-ios">No</span></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div><!-- /.modal-content -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <h4 class="card-title mb-4">Course Details</h4>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_filter"><label>Search:<input type="text" class="form-control form-control-sm"
                        placeholder="Search" [(ngModel)]="searchText"></label>
                  </div>
                </div>
              </div>
              <!-- {{courselist.courses|json}} -->
              <table class="table mb-0">

                <thead class="thead-light">
                  <tr>
                    <th>No.</th>
                    <th>Course Name</th>
                    <th>Course Expiry Date</th>
                    <th>Active / Inactive</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr [hidden]="courselist.courses?.length">
                    <th colspan="7">
                      <h5 class="text-danger text-center">No Records found</h5>
                    </th>
                  </tr>
                  <tr
                    *ngFor="let list of courselist.courses | paginate: { itemsPerPage: 10, currentPage: p } | filter: searchText; let i = index ">
                    <td scope="row" data-title="No.">{{i+1}}</td>
                    <td data-title="Course Name">{{list.courseName}}</td>
                    <td data-title="Course Expiry Date">{{list.expiryDate}}</td>
                    <td data-title="Active / Inactive">

                      <label class="switch">
                        <!-- <input type="checkbox" id="verify" value="1" checked=""> -->
                        <input type="checkbox" id="verify" value="1" *ngIf="list.assignedUser[0].status == 'Active'"
                          [checked]="true" (click)="ChangeAsigncourseStatus($event,list._id)">
                        <input type="checkbox" id="verify" value="1" *ngIf="list.assignedUser[0].status == 'Inactive'"
                          [checked]="false" (click)="ChangeAsigncourseStatus($event,list._id)">
                        <span class="slider round"></span>
                      </label>
                    </td>
                    <td data-title="Actions">
                      <button type="button" class="mr-2 text-primary btn_style" data-toggle="modal"
                        data-target=".edit_detail" (click)="EditAsignCourse(list)"><i
                          class="mdi mdi-pencil font-size-18"></i></button>
                      <a href="javascript:void(0);" class="text-danger" data-toggle="tooltip" data-placement="top"
                        title="" data-original-title="Delete" data-target="#confirmodal" data-toggle="modal"
                        data-placement="top" (click)="deletemodal(list)"><i
                          class="mdi mdi-trash-can font-size-18"></i></a>
                    </td>
                  </tr>


                </tbody>
              </table>
              <div class="row">
                <div class="col-sm-12 col-md-5">
                  <!-- <div class="dataTables_info">Showing 1 to 50 of 51 entries</div> -->
                </div>
                <div class="col-sm-12 col-md-7">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <!-- <ul class="pagination pagination-rounded">
                      <li class="paginate_button page-item previous disabled"><a href="#" class="page-link"><i
                            class="mdi mdi-chevron-left"></i></a></li>
                      <li class="paginate_button page-item active"><a href="#" class="page-link">1</a></li>
                      <li class="paginate_button page-item "><a href="#" class="page-link">2</a>
                      </li>
                      <li class="paginate_button page-item next"><a href="#" class="page-link"><i
                            class="mdi mdi-chevron-right"></i></a></li>
                    </ul> -->
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>

                  </div>
                </div>
              </div>
              <div class="modal fade edit_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title mt-0">Edit Course</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form [formGroup]="EditAsignCourseForm" (ngSubmit)="UpdateAsignCourse()">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="form-group">
                              <label class="control-label">Course
                                Name</label>
                              <input type="text" class="form-control" formControlName="coursename" name="coursename"
                                id="coursename" value="{{coursename}}" readonly>
                              <!-- <select class="form-control" formControlName="coursename">
                                <option value="" [disabled]="true">Select</option>
                                <option [disabled]="true" *ngFor="let list of Getcourselist" value="{{list._id}}">
                                  {{list.courseName}}
                                </option>
                              </select> -->
                              <div *ngIf="submitted && f.coursename.errors" class="invalid-feedback"
                                style="display: block;">
                                <div *ngIf="f.coursename.errors.required">Course is
                                  required</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <div class="form-group">
                              <label class="control-label">Course Expiry Date
                              </label>
                              <div class="input-group">
                                <!-- <input type="text" class="form-control"
                                                                    data-provide="datepicker"
                                                                    data-date-format="dd M, yyyy"
                                                                    data-date-autoclose="true">
                                                                <div class="input-group-append">
                                                                    <span class="input-group-text"><i
                                                                            class="mdi mdi-calendar"></i></span>
                                                                </div> -->
                                <input type="date" class="form-control" formControlName="coursedate"
                                  data-provide="datepicker" data-date-format="yyyy-mm-dd" data-date-autoclose="true">
                                <div *ngIf="submitted && f.coursedate.errors" class="invalid-feedback"
                                  style="display: block;">
                                  <div *ngIf="f.coursedate.errors.required">Course is
                                    required</div>
                                </div>
                              </div><!-- input-group -->
                            </div>
                          </div>

                        </div>
                        <button class="btn btn-warning" type="submit">Save</button>
                      </form>
                    </div>
                  </div><!-- /.modal-content -->
                </div><!-- /.modal-dialog -->
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-3">
            <div class="table-responsive">
              <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6 filter_boxs">

                  <!-- <div class="dataTables_filter"><label>Search:<input type="search" class="form-control form-control-sm"
                        placeholder="" [(ngModel)]="searchText" (input)="applyFilter($event.target.value)"></label>
                  </div> -->
                </div>
              </div>
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 20px;">
                      No.
                    </th>
                    <th style="width: 130px;">Course</th>
                    <th style="width: 300px;">Chapter</th>
                    <th>Total Question</th>
                    <th>Correct Answer</th>
                    <th>wrong Answer</th>

                  </tr>
                </thead>
                <tr [hidden]="courselist.assessmentHistory?.length">
                  <th colspan="7">
                    <h5 class="text-danger text-center">No Records found</h5>
                  </th>
                </tr>
                <tbody>

                  <tr *ngFor="let history of courselist.assessmentHistory let i = index ">
                    <td data-title=" No.">{{i+1}}</td>
                    <td data-title="Course Name">{{history?.chapterId.courseName}}</td>
                    <td data-title="Chapters">{{history?.chapterId.chapterName}}</td>
                    <td data-title="Total Question">{{history?.questions.length}} </td>
                    <td data-title="Currect Answer">{{history.totalCorrectAnwser}}</td>
                    <td data-title="wrong Answer">{{history.totalWrongAnwser}}</td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-sm-12 col-md-5">

                </div>
                <div class="col-sm-12 col-md-7">
                  <!-- <div class="dataTables_paginate paging_simple_numbers">
                    <ul class="pagination pagination-rounded">
                      <li class="paginate_button page-item previous disabled"><a href="#" class="page-link"><i
                            class="mdi mdi-chevron-left"></i></a></li>
                      <li class="paginate_button page-item active"><a href="#" class="page-link">1</a></li>
                      <li class="paginate_button page-item "><a href="#" class="page-link">2</a>
                      </li>
                      <li class="paginate_button page-item next"><a href="#" class="page-link"><i
                            class="mdi mdi-chevron-right"></i></a></li>
                    </ul>
                  </div> -->
                  <!-- <pagination-controls id="courseList" responsive="true" previousLabel="Prev" nextLabel="Next"
                      (pageChange)="onTableDataChange($event)">
                    </pagination-controls> -->
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div> <!-- container-fluid -->
</div>
<!-- End Page-content -->

<div class="modal fade alert_popup" tabindex="-1" id="confirmodal" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">

        <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
          <!-- <div class="sc-ion-alert-ios-backdrop"></div> -->
          <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
            <div class="alert-head sc-ion-alert-ios">
              <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete
                Assign Course</h2>
            </div>
            <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you
              sure you want to delete?</div>
            <div class="alert-button-group sc-ion-alert-ios">
              <button type="button" class="alert-button"><span class="alert-button-inner sc-ion-alert-ios"
                  (click)="DeleteAsignCourse(courseid)">Yes</span>
              </button>
              <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
                  class="alert-button-inner sc-ion-alert-ios">No</span></button>
            </div>
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>