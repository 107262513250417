import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../Services/core/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {
  constructor(
    private storage: StorageService,
    private router: Router
  ) {

  }
  canActivate(

    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const savedData = this.storage.getSavedData();
    if (savedData.auth_token != null || savedData.auth_token != undefined) {

      return true;
      // return false;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

}
