<div class="modal fade add_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mt-0">Add
                    Question & Answers</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="AddmoreQuestionForm">
                    <div class="form-group">
                        <label class="control-label">Question</label>
                        <input type="text" class="form-control form-control-sm b-radius-0" formControlName="question">
                        <div *ngIf="submitted && f.question.errors" class="invalid-feedback" style="display: block;">
                            <div *ngIf="f.question.errors.required">Question is required</div>
                        </div>
                    </div>
                    <div id="answer" class="form-group">
                        <label class="control-label width-full">Answer
                            <span class="float-right">Mark Correct Option</span></label>
                        <div class="form-group add-answer answer_row" formArrayName="options"
                            *ngFor="let field of AddmoreQuestionForm.get('options')['controls']; let i = index;">
                            <div [formGroupName]="i">
                                <input type="text" class="form-control form-control-sm b-radius-0 input-answer"
                                    formControlName="answer">
                                <!-- <input type="radio" formControlName="optradio"
                                    class="form-control form-control-sm b-radius-0" value="{{i+1}}" name="optradio"> -->

                                <label class="radio-box"><input type="radio" formControlName="optradio"
                                        (click)="ChooseCorrectAns(i+1)" value="{{i+1}}"
                                        name="optradio"><span>radio</span></label>
                                <a class="text-danger ml-3" (click)="removefeilds(i)"><i
                                        class="mdi mdi-trash-can font-size-18"></i></a>

                            </div>
                            <!-- <button (click)="removefeilds(i)" id="remove0"
                                class="btn btn-danger remove-me float-left">Remove</button> -->


                        </div>
                        <div class="form-group add-answer mb-0">
                            <!-- <button id="add-more" name="add-more" class="btn btn-warning align-items-center float-left"
                                (click)="addAnswer()">Add
                                More</button> -->
                            <a class="icon-plus ml-3 float-right" (click)="addAnswer()"> <i class="fas fa-plus"></i></a>
                        </div>
                        <!-- <div class="form-group add-answer">
                            <input type="text" class="form-control form-control-sm b-radius-0">
                            <button id="add-more" name="add-more" class="btn btn-warning align-items-center">Add
                                More</button>
                        </div> -->
                    </div>
                    <button class="btn btn-warning" type="submit" (click)="SaveQuestion()">Save</button>
                </form>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->