import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MustMatch } from 'src/app/constants/regex.constants';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
declare var $: any;
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  sidePanelEnabled: boolean = false;
  showLeftPanel: boolean = true;
  AdminName: any;
  ChangePasswordForm: FormGroup;
  submitted = false;
  errormsg: any;
  constructor(private storage: StorageService,
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private commonservice: CommonService,
    private router: Router

  ) {

    const savedData = this.storage.getSavedData();
    this.AdminName = savedData["user_details"].name
  }

  ngOnInit(): void {
    this.ChangePasswordForm = this.formBuilder.group({
      OldPassword: ['', Validators.required],
      NewPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
    }, {
      validator: MustMatch('NewPassword', 'confirmPassword')
    }
    )
  }
  enableSidePanel() {
    this.sidePanelEnabled = !this.sidePanelEnabled;
  }
  get f() { return this.ChangePasswordForm.controls; }
  changePassword() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.ChangePasswordForm.invalid) {
      return;
    }

    let postdata = {
      oldPassword: this.ChangePasswordForm.value.OldPassword,
      newPassword: this.ChangePasswordForm.value.NewPassword,
      confirmPassword: this.ChangePasswordForm.value.confirmPassword
    }
    this.commonservice.presentSpinner();
    this.auth.ChangePassword(postdata).then(
      (res) => {
        if (res) {

          if (res.code == 200) {
            this.commonservice.dismissSpinner();
            $('.change_password').modal('hide');
            this.ChangePasswordForm.reset();
            this.commonservice.presentSuccessToast('', res.message);
          } else if (res.code == 422) {
            this.commonservice.dismissSpinner();
            this.commonservice.presentErrorToast('', res.message);
          }
        } else {

        }

      }
    )
  }


  showHideLeftPanel() {
    this.showLeftPanel = !this.showLeftPanel;
  }
  logout() {

    this.commonservice.presentSpinner();
    this.auth.Logout().then(
      (res) => {
        if (res) {
          if (res.code == 200) {
            localStorage.clear();
            this.router.navigate(['/']);
            this.commonservice.dismissSpinner();
            this.commonservice.presentSuccessToast('', res.message);
          } else if (res.code == 422) {
            this.commonservice.presentErrorToast('', res.message);
          }
        } else {

        }

      }
    )

  }
}
