import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { StorageService } from 'src/app/Services/core/storage/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SigninComponent implements OnInit {
  LoginForm: FormGroup;
  submitted = false;
  errormsg: string = ''
  constructor(
    private formBuilder: FormBuilder,
    private authenticate: AuthenticationService,
    private commonService: CommonService,
    private storage: StorageService,
    private tosterservice: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.LoginForm = this.formBuilder.group({
      Username: ['', Validators.required],
      Password: ['', Validators.required],
    })
  }
  get f() { return this.LoginForm.controls; }

  login() {

    this.submitted = true;
    this.commonService.presentSpinner();

    // stop here if form is invalid
    if (this.LoginForm.invalid) {
      return;
    }

    let loginBody = {
      email: this.LoginForm.value.Username,
      password: this.LoginForm.value.Password
    }
    this.authenticate.login(loginBody).then(
      (res) => {

        if (res) {
          if (res.status == 200) {
            this.commonService.dismissSpinner();
            // this.tosterservice.success('message', 'title');

            this.router.navigate(['/admin/subscribers']);
            this.LoginForm.reset();
          } else if (res.code == 422) {
            this.errormsg = res.message;
          }
        } else {
          this.commonService.dismissSpinner();
        }

      }
    )
  }

  forgotpasswormsg: boolean;
  forgotpassword() {
    // [routerLink] = "['admin/forgot-password']" 


    let email = { "email": "shahid1@gmail.com" }
    this.authenticate.forgotpassword(email).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code == 200) {
            // this.tosterservice.success('message', 'title');
            this.forgotpasswormsg = true;
            setTimeout(() => {
              this.forgotpasswormsg = false;
            }, 6000);
            // this.router.navigate([res.resetUrl]);
          } else if (res.code == 422) {
            this.errormsg = res.message;
          }
        } else {

        }

      }
    )

    this.router.navigate([''])
  }

}
