import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpWrapperService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private getUrl(endpoint: string) {
    return environment.baseUrl + endpoint;
  }

  public post(endpoint: string, options?: any, options1?: any): Observable<any> {
    let url = this.getUrl(endpoint);
    return this.httpClient.post(url, options, options1);
  }

  public get(endpoint: string, options?: any): Observable<any> {
    let url = this.getUrl(endpoint);
    return this.httpClient.get(url, options);
  }

  public delete(endpoint: string): Observable<any> {
    let url = this.getUrl(endpoint);
    return this.httpClient.delete(url);
  }

  public put(endpoint: string, payload: any): Observable<any> {
    let url = this.getUrl(endpoint);
    return this.httpClient.put(url, payload);
  }
}
