import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EventEmitter } from 'events';
import { eventKeys } from 'src/app/constants/event-keys.constants';
import { ChapterserviceService } from 'src/app/Services/chapterservice/chapterservice.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { EventsService } from 'src/app/Services/core/events/events.service';
declare var $: any;
@Component({
  selector: 'app-chaptermodal',
  templateUrl: './chaptermodal.component.html',
  styleUrls: ['./chaptermodal.component.scss']
})

export class ChaptermodalComponent implements OnInit {
  // courseid: string;
  ValueFromComponent1: any;
  AddChapterForm: FormGroup;
  submitted = false;
  @Input() courseid: any;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private events: EventsService,
    private chapterservice: ChapterserviceService,
    private commonservice: CommonService,
  ) { }

  ngOnInit(): void {
    this.AddChapterForm = this.formBuilder.group({
      ChapterImage: ['', Validators.required],
      chapterName: ['', Validators.required],
      Chapterdescription: ['', Validators.required],
    })
  }
  get f() { return this.AddChapterForm.controls; }
  fileToUpload: File = null;
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  AddChapter() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.AddChapterForm.invalid) {
      return;
    }
    this.commonservice.presentSpinner();
    var frmData = new FormData();
    frmData.append("image", this.fileToUpload);
    frmData.append("description", this.AddChapterForm.value.Chapterdescription);
    frmData.append("courseId", this.courseid);
    frmData.append("chapterName", this.AddChapterForm.value.chapterName);

    this.chapterservice.AddChapter(frmData).then(
      (res) => {
        this.commonservice.dismissSpinner();
        if (res) {
          if (res.code === 200) {
            this.commonservice.dismissSpinner();
            this.AddChapterForm.reset();
            $('.add_detail').modal('hide');
            this.submitted = false;
            this.commonservice.presentSuccessToast('', res.message);

          }
        }
      }
    )
  }
}
