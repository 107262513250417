import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private toastNotification: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  presentSuccessToast(title: string, message: string) {

    this.toastNotification.success(message, title);
  }

  presentErrorToast(title: string, message: string) {
    this.toastNotification.error(message, title);
  }

  presentSpinner() {
    this.spinner.show();
    setTimeout(() => {
      this.dismissSpinner();
    }, 5000);
  }

  dismissSpinner() {
    this.spinner.hide();
  }

  // handleError(error: any) {
  //   console.log('error ====>>>', error.error);
  // }
  handleError(error: any) {
    console.log('error ====>>>', error.error);
    if (error.error.code == 422) {
      this.toastNotification.error(error.error.message)
    }
  }
}
