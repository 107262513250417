<div class="page-content">
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item"><a href="javascript: void(0);">IUT</a></li>
                            <li class="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>
        <!-- end page title -->


        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="float-right d-none d-md-inline-block">
                            <div class="btn-group mb-2">
                                <button type="button" class="btn btn-sm btn-light">Weeks</button>
                                <button type="button" class="btn btn-sm btn-light active">Months</button>
                                <button type="button" class="btn btn-sm btn-light">Years</button>
                            </div>
                        </div>
                        <h4 class="card-title mb-4">Course Enrollment</h4>
                        <div>
                            <!-- <div id="line-column-chart" class="apex-charts" dir="ltr"></div> -->
                            <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                                [yaxis]="chartOptions.yaxis" [xaxis]="chartOptions.xaxis" [labels]="chartOptions.labels"
                                [stroke]="chartOptions.stroke" [title]="chartOptions.title"
                                [dataLabels]="chartOptions.dataLabels" [fill]="chartOptions.fill"
                                [tooltip]="chartOptions.tooltip"></apx-chart>
                        </div>
                    </div>

                    <div class="card-body border-top text-center">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="d-inline-flex">
                                    <h5 class="mr-2">No. of Students 12,253</h5>
                                    <div class="text-success">
                                        <i class="mdi mdi-menu-up font-size-14"> </i>2.2 %
                                    </div>
                                </div>
                                <p class="text-muted text-truncate mb-0">This month</p>
                            </div>

                            <div class="col-sm-4">
                                <div class="mt-4 mt-sm-0">
                                    <p class="mb-2 text-muted text-truncate"><i
                                            class="mdi mdi-circle text-primary font-size-10 mr-1"></i> This Year :</p>
                                    <div class="d-inline-flex">
                                        <h5 class="mb-0 mr-2">No. of Students 34,254</h5>
                                        <div class="text-success">
                                            <i class="mdi mdi-menu-up font-size-14"> </i>2.1 %
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="mt-4 mt-sm-0">
                                    <p class="mb-2 text-muted text-truncate"><i
                                            class="mdi mdi-circle text-success font-size-10 mr-1"></i> Previous Year :
                                    </p>
                                    <div class="d-inline-flex">
                                        <h5 class="mb-0">No. of Students 32,695</h5>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->

    </div> <!-- container-fluid -->
</div>