import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { CommonService } from 'src/app/Services/common/common.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  ResetPasswordForm: FormGroup;
  submitted = false;
  token: any;
  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private commonSerivce: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {

    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params.token
    });
  }

  ngOnInit(): void {
    this.ResetPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmpassword: ['', Validators.required],
    })
  }
  get f() { return this.ResetPasswordForm.controls; }

  ResetPassword() {

    this.submitted = true;
    if (this.ResetPasswordForm.invalid) {
      return;
    }
    this.commonSerivce.presentSpinner();
    let postdata = {
      "password": this.ResetPasswordForm.value.password,
      "confirmPassword": this.ResetPasswordForm.value.confirmpassword,
      "token": this.token,

    }
    this.auth.RestPassword(postdata).then(
      (res) => {

        if (res) {
          if (res.code === 200) {
            this.ResetPasswordForm.reset();
            this.submitted = false;
            this.commonSerivce.dismissSpinner();
            this.router.navigate(['/']);
            this.commonSerivce.presentSuccessToast('', res.message);

          }
        }
      }
    );
  }

}
