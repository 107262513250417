<div class="page-content">
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <div class="page-title-right page-title-right2">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item active">Assessments</li>
                        </ol>
                    </div>
                    <div class="page-title-right2">
                        <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
                            data-toggle="modal" data-target=".add_detail">Add</button>
                        <div class="modal fade add_detail" tabindex="-1" role="dialog"
                            aria-labelledby="mySmallModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title mt-0">Add Assessments</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label class="control-label">Name</label>
                                                        <input type="text" class="form-control" name="course-name"
                                                            id="course-name">
                                                    </div>
                                                </div>
                                            </div>
                                            <button class="btn btn-warning" type="submit">Save
                                                Detail</button>
                                        </form>
                                    </div>
                                </div><!-- /.modal-content -->
                            </div><!-- /.modal-dialog -->
                        </div><!-- /.modal -->

                    </div>

                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body pt-3">
                        <div class="table-responsive">
                            <div class="row">
                                <div class="col-sm-12 col-md-6"></div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="dataTables_filter"><label>Search:<input type="search"
                                                class="form-control form-control-sm" placeholder=""></label>
                                    </div>
                                </div>
                            </div>
                            <table class="table mb-0">
                                <thead class="thead-light">
                                    <tr>
                                        <th style="width: 20px;">
                                            No.
                                        </th>
                                        <th>Name</th>
                                        <th>No. of questions</th>
                                        <th>Course tagged</th>
                                        <th>Draft / Published</th>
                                        <th style="width: 120px;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td data-title="N0.">
                                            1
                                        </td>
                                        <td data-title="No. of questions">Assessment 1</td>
                                        <td data-title="Name">3</td>
                                        <td data-title="Assessment">Course tagged 1</td>
                                        <td data-title="Draft / Published">
                                            <label class="switch">
                                                <input type="checkbox" id="verify" value="1" checked="">
                                                <span class="slider round"></span>
                                            </label>
                                        </td>
                                        <td data-title="Actions">
                                            <a href="javascirpt:void(0)" class="mr-2 text-solitud" data-toggle="tooltip"
                                                data-placement="top" title="" data-original-title="View"><i
                                                    class="fas fa-eye font-size-18"></i></a>
                                            <a href="javascript:void(0);" class="text-danger" data-toggle="tooltip"
                                                data-placement="top" title="" data-original-title="Delete"><i
                                                    class="mdi mdi-trash-can font-size-18"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="row">
                                <div class="col-sm-12 col-md-5">
                                    <div class="dataTables_info">Showing 1 to 10 of 11 entries</div>
                                </div>
                                <div class="col-sm-12 col-md-7">
                                    <div class="dataTables_paginate paging_simple_numbers">
                                        <ul class="pagination pagination-rounded">
                                            <li class="paginate_button page-item previous disabled"><a
                                                    href="javascript:void(0)" class="page-link"><i
                                                        class="mdi mdi-chevron-left"></i></a></li>
                                            <li class="paginate_button page-item active"><a href="#"
                                                    class="page-link">1</a></li>
                                            <li class="paginate_button page-item "><a href="javascript:void(0)"
                                                    class="page-link">2</a>
                                            </li>
                                            <li class="paginate_button page-item next"><a href="javascript:void(0)"
                                                    class="page-link"><i class="mdi mdi-chevron-right"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->

    </div> <!-- container-fluid -->
</div>