<div class="modal fade add_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title mt-0">Add Course </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="AsignCourseForm" (ngSubmit)="AsignCourse()">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <!-- {{courselist|json}} -->
                                <label class="control-label">Course Select</label>
                                <select class="form-control" formControlName="coursename">
                                    <option value="">Select</option>
                                    <option *ngFor="let list of courselist" value="{{list._id}}">{{list.courseName}}
                                    </option>

                                </select>
                                <div *ngIf="submitted && f.coursename.errors" class="invalid-feedback"
                                    style="display: block;">
                                    <div *ngIf="f.coursename.errors.required">Course is
                                        required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="control-label">Course Expiry Date
                                </label>
                                <div class="input-group">
                                    <input type="date" class="form-control" formControlName="coursedate"
                                        data-provide="datepicker" data-date-format="yyyy-mm-dd"
                                        data-date-autoclose="true">
                                    <!-- <div class="input-group-append">
                                        <span class="input-group-text"><i class="mdi mdi-calendar"></i></span>
                                    </div> -->
                                    <div *ngIf="submitted && f.coursedate.errors" class="invalid-feedback"
                                        style="display: block;">
                                        <div *ngIf="f.coursedate.errors.required">Course is
                                            required</div>
                                    </div>
                                </div><!-- input-group -->
                            </div>
                        </div>

                    </div>
                    <button class="btn btn-warning" type="submit">Save</button>
                </form>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->