import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { storageKeys } from 'src/app/constants/storage-keys.constants';
import { StorageService } from '../../storage/storage.service';
@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private storage: StorageService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const savedData = this.storage.getSavedData();
    console.log('auth token ===>>>', savedData[storageKeys.auth_token])
    if (savedData && savedData[storageKeys.auth_token]) {
      const header = {
        "Authorization": savedData[storageKeys.auth_token]
      }

      const updatedRequest = request.clone({
        headers: new HttpHeaders(header)
      });
      return next.handle(updatedRequest).pipe(
        tap(
          (event: HttpEvent<any>) => {
          }, (error) => {
            if (error instanceof HttpErrorResponse) {

            }
          }
        )
      )
    } else {
      return next.handle(request);
    }
  }
}
