import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { eventKeys } from 'src/app/constants/event-keys.constants';
import { MustMatch } from 'src/app/constants/regex.constants';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { ChapterserviceService } from 'src/app/Services/chapterservice/chapterservice.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { EventsService } from 'src/app/Services/core/events/events.service';
import { CourseService } from 'src/app/Services/courseservice/course.service';
declare var $: any;
@Component({
  selector: 'app-asigncoursedetails',
  templateUrl: './asigncoursedetails.component.html',
  styleUrls: ['./asigncoursedetails.component.scss']
})
export class AsigncoursedetailsComponent implements OnInit {
  userid: any;
  courselist: any = [];
  // AsignCourseForm: FormGroup;
  EditAsignCourseForm: FormGroup;
  ChangePasswordForm: FormGroup;
  EditUserInfoForm: FormGroup;
  submitted = false;
  p: number = 1;
  searchText: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private chapterservice: ChapterserviceService,
    private authenticate: AuthenticationService,
    private commonService: CommonService,
    private courseservice: CourseService,
    private events: EventsService,
    private router: Router

  ) {
    this.events.subscribe(eventKeys.usercreated, () => {
      this.GetAsignCourse();
    })
    this.activatedRoute.queryParams.subscribe(params => {
      this.userid = params.userid

    });
  }

  ngOnInit(): void {
    this.GetAsignCourse();
    this.GetCourseList();
    this.EditAsignCourseForm = this.formBuilder.group({
      coursename: ['', Validators.required],
      coursedate: ['', Validators.required],
    })

    this.ChangePasswordForm = this.formBuilder.group({
      NewPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      // email: ['', [Validators.required, Validators.email]],
    },
      {
        validator: MustMatch('NewPassword', 'confirmPassword')
      })

    this.EditUserInfoForm = this.formBuilder.group({
      UserImg: ['', ''],
      Name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    })
  }
  get f() { return this.EditAsignCourseForm.controls; }
  get f1() { return this.ChangePasswordForm.controls; }
  get f2() { return this.EditUserInfoForm.controls; }



  img: any;
  GetAsignCourse() {

    this.commonService.presentSpinner();
    this.chapterservice.userid = this.userid;
    this.chapterservice.getcoursedetail().then(
      (res) => {
        if (res) {
          if (res.code == 200) {
            this.courselist = res.userData;
            // this.fileToUpload = null
            this.img = this.courselist.profileImage
            this.EditUserInfoForm.patchValue({
              UserImage: this.courselist.profileImage,
              Name: this.courselist.name,
              email: this.courselist.email,
            });
            this.commonService.dismissSpinner();
          } else if (res.code == 400) {
            this.commonService.dismissSpinner();
            // this.commonService.presentErrorToast('', 'No records found');
          }
        } else {

        }

      }
    )
  }
  Getcourselist: any = [];
  GetCourseList() {

    this.commonService.presentSpinner();
    this.courseservice.getUserCourseList().then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code == 200) {
            this.Getcourselist = res.courseList;
          } else if (res.code == 401) {

          }
        } else {

        }

      }
    )
  }
  butDisabled: boolean = true
  coursename: any;
  cid: any;
  EditAsignCourse(list) {
    debugger
    this.cid = list._id;
    this.butDisabled = false;
    this.Getcourselist.forEach(e => {
      if (e._id == list._id) {
        this.coursename = e.courseName;
      }
    });
    this.EditAsignCourseForm.patchValue({
      coursename: list._id,
      coursedate: list.expiryDate,

    });
  }
  UpdateAsignCourse() {

    if (this.EditAsignCourseForm.invalid) {
      return;
    }
    this.commonService.presentSpinner();
    let postdata = {
      'courseId': this.cid,
      'userId': this.userid,
      'expiryDate': this.EditAsignCourseForm.value.coursedate,
      "action": "edit"
    }
    this.courseservice.Asigncourse(postdata).then(
      (res) => {

        if (res) {
          if (res.code == 200) {
            $('.edit_detail').modal('hide');
            this.commonService.dismissSpinner();
            // this.tosterservice.success('message', 'title');
            this.commonService.presentSuccessToast('', res.message);
            this.EditAsignCourseForm.reset();
          } else if (res.code == 422) {
          }
        } else {
          this.commonService.dismissSpinner();
        }

      }
    )
  }
  fileToUpload: File = null;
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  UserAction(courselist, action) {
    this.commonService.presentSpinner();
    if (action == 'changepassword') {
      this.submitted = true;
      if (this.ChangePasswordForm.invalid) {
        return;
      }

      this.authenticate.Uid = courselist._id;
      let postdata = {
        newPassword: this.ChangePasswordForm.value.NewPassword,
        confirmPassword: this.ChangePasswordForm.value.confirmPassword
      }
      this.authenticate.UserChangePassword(postdata).then(
        (res) => {
          if (res) {

            if (res.code == 200) {
              this.commonService.dismissSpinner();
              $('.changepassuser').modal('hide');
              this.ChangePasswordForm.reset();
              this.commonService.presentSuccessToast('', res.message);
            } else if (res.code == 422) {
              this.commonService.dismissSpinner();
              this.commonService.presentErrorToast('', res.message);
            }
          } else {

          }

        }
      )
    } else if (action == 'updateuserinfo') {
      this.submitted = true;
      if (this.EditUserInfoForm.invalid) {
        return;
      }
      var frmData = new FormData();
      frmData.append("profileImage", this.fileToUpload);
      frmData.append("name", this.EditUserInfoForm.value.Name);
      frmData.append("email", this.EditUserInfoForm.value.email);
      this.authenticate.Uid = this.courselist._id;
      this.authenticate.EditUser(frmData).then(
        (res) => {
          this.commonService.dismissSpinner();
          if (res) {
            this.commonService.dismissSpinner();
            if (res.code === 200) {
              // this.EditUserInfoForm.reset();
              this.GetAsignCourse();
              $('.edit_user_detail').modal('hide');
              this.commonService.presentSuccessToast('', res.message);

            }
          } else {
            this.commonService.dismissSpinner();
          }
        }
      );
    } else if (action == 'deleteuser')
      this.authenticate.deleteUserList(courselist._id).then(
        (res: any) => {
          this.commonService.dismissSpinner();
          if (res && res.code === 200) {

            $('.alert_popup').modal('hide');
            this.router.navigate(['/admin/subscribers']);

            this.commonService.presentSuccessToast('', res.message);

          }
        }
      )
    // stop here if form is invalid

  }

  ChangeUserStatus(event, userid) {

    this.commonService.presentSpinner();
    this.authenticate.Uid = userid;
    var data;
    if (event.srcElement.checked == true) {
      data = { "status": "Active" }
    } else {
      data = { "status": "Inactive" }
    }
    this.authenticate.ChangeUserStatus(data).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          this.commonService.dismissSpinner();
          if (res.code === 200) {
            this.GetAsignCourse();
            this.commonService.presentSuccessToast('', res.message);

          }
        } else {
          this.commonService.dismissSpinner();
        }
      }
    );

  }
  courseid: any;
  deletemodal(item) {

    this.courseid = item._id;
  }

  DeleteAsignCourse(courseid) {

    this.commonService.presentSpinner();

    this.authenticate.Uid = this.userid;
    this.authenticate.Deleteasigncourse(courseid).then(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.commonService.dismissSpinner();
            // $('#confirmodal').hide
            $('#confirmodal').modal('hide');
            this.GetAsignCourse();
            // this.commonService.presentSuccessToast('', res.message);

          }
        } else {
          this.commonService.dismissSpinner();
        }
      }
    );
  }

  ChangeAsigncourseStatus(event, courseid) {

    this.commonService.presentSpinner();
    this.authenticate.Uid = courseid;
    var data;
    if (event.srcElement.checked == true) {
      data = { userId: this.userid, "status": "Active" }
    } else {
      data = { userId: this.userid, "status": "Inactive" }
    }
    this.authenticate.ChangeAsignCourseStatus(data).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          this.commonService.dismissSpinner();
          if (res.code === 200) {
            this.GetAsignCourse();
            this.commonService.presentSuccessToast('', res.message);

          }
        } else {
          this.commonService.dismissSpinner();
        }
      }
    );

  }
}
