<div class="page-content">
  <div class="container-fluid">

    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Courses</li>
            </ol>
          </div>
          <div class="page-title-right2">
            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light" data-toggle="modal"
              data-target="#add_detail">Add</button>
            <app-coursemodal></app-coursemodal>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-3">
            <div class="table-responsive">
              <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6 filter_boxs">
                  <!-- <div class="dataTables_filter_select">
                                        <select class="form-control-sm filter_select">
                                            <option> Course Name Select</option>
                                            <option>Course Name 1</option>
                                            <option>Course Name 2</option>
                                        </select>
                                    </div> -->
                  <div class="dataTables_filter"><label>Search:<input type="search" class="form-control form-control-sm"
                        placeholder="" [(ngModel)]="searchText" (input)="applyFilter($event.target.value)"></label>
                  </div>
                </div>
              </div>
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 20px;">
                      No.
                    </th>
                    <th style="width: 130px;">Course Picture</th>
                    <th style="width: 300px;">Course Name</th>
                    <th>Chapters</th>
                    <th>No. of Users</th>
                    <th>Draft / Published</th>
                    <th style="width: 120px;">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr [hidden]="courselist?.length">
                    <th colspan="7">
                      <h6 class=" mb-0" style="color: #545a60;">No courses added</h6>
                    </th>
                  </tr>

                  <tr [hidden]="!courselist?.length"
                    *ngFor="let list of courselist| paginate: {id: 'courseList', itemsPerPage: limit, currentPage: page,totalItems: count } ; let i = index">
                    <!-- | filter: searchText -->
                    <!-- {{list|json}} -->
                    <td data-title=" No.">
                      <!-- {{i+1}} -->
                      {{ limit * (page - 1) + i + 1 }}
                    </td>
                    <td data-title="Course Picture">
                      <div class="courses-bg" style="background-image:url({{list.image}})"></div>
                      <!-- <img src="" alt=""style="width: 100px;"> -->
                    </td>
                    <td data-title="Course Name">{{list.courseName}}</td>
                    <!-- <td class="description-td" data-title="Description">
                                            <p class="description-text" [innerHtml]="list.description"></p>
                                        </td> -->
                    <td data-title="Chapters">{{list.chapterCount}}</td>

                    <td data-title="No. of Users">
                      {{list.assignedUserCount}}
                    </td>
                    <td data-title="Draft / Published">
                      <label class="switch">
                        <!-- <input type="checkbox" id="verify" value="1" checked=""> -->
                        <input type="checkbox" id="verify" value="1" *ngIf="list.status == 'Active'" [checked]="true"
                          (click)="ChangeCurseStatus($event,list._id)">
                        <input type="checkbox" id="verify" value="1" *ngIf="list.status == 'Inactive'" [checked]="false"
                          (click)="ChangeCurseStatus($event,list._id)">

                        <span class="slider round"></span>
                      </label>
                    </td>
                    <td data-title="Actions">
                      <a href="javascript:void(0)" class="mr-2 text-solitud" data-toggle="tooltip" data-placement="top"
                        title="" data-original-title="View" aria-describedby="tooltip11135"
                        (click)="ChapterList(list)"><i class="fas fa-eye font-size-18"></i></a>
                      <button type="button" class="mr-2 text-primary btn_style" data-toggle="modal"
                        data-target=".edit_detail" (click)="openeditcoursemodal(list)"><i
                          class="mdi mdi-pencil font-size-18"></i></button>
                      <a href="javascript:void(0);" class="text-danger" data-toggle="tooltip" data-placement="top"
                        data-target="#confirmodal" title="" data-original-title="Delete"
                        (click)="DeleteCourse(list._id,'open')"><i class="mdi mdi-trash-can font-size-18"></i></a>
                    </td>
                  </tr>


                </tbody>
              </table>
              <div class="row">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info">Showing {{pageData}} of {{count}} entries</div>
                </div>
                <div class="col-sm-12 col-md-7" [hidden]="!courselist?.length">
                  <!-- <div class="dataTables_paginate paging_simple_numbers">
                                        <ul class="pagination pagination-rounded">
                                            <li class="paginate_button page-item previous disabled"><a href="#"
                                                    class="page-link"><i class="mdi mdi-chevron-left"></i></a></li>
                                            <li class="paginate_button page-item active"><a href="#"
                                                    class="page-link">1</a></li>
                                            <li class="paginate_button page-item "><a href="#" class="page-link">2</a>
                                            </li>
                                            <li class="paginate_button page-item next"><a href="#" class="page-link"><i
                                                        class="mdi mdi-chevron-right"></i></a></li>
                                        </ul>
                                    </div> -->
                  <pagination-controls id="courseList" responsive="true" previousLabel="Prev" nextLabel="Next"
                    (pageChange)="onTableDataChange($event)">
                  </pagination-controls>
                </div>
              </div>
              <div class="modal fade edit_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title mt-0">Edit Course Detail</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form [formGroup]="EditCourseForm" (ngSubmit)="UpdateCourse()">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="form-group">
                              <label class="control-label">Upload Picture</label>
                              <div class="custom_file custom_img_input">
                                <input type="file" class="custom_file_input" formControlName="CourseImage"
                                  id="customFile" (change)="handleFileInput($event.target.files)">
                                <div class="user_img"
                                  style="background-color: rgb(245, 245, 245);background-image: url({{img}});"></div>
                                <!-- <img src="{{img}}" alt="" style="width: 100px;"> -->

                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <div class="form-group">
                              <label class="control-label">Name</label>
                              <input type="text" class="form-control" formControlName="Coursename" name="Coursename"
                                id="Coursename">
                              <div *ngIf="submitted && f.Coursename.errors" class="invalid-feedback"
                                style="display: block;">
                                <div *ngIf="f.Coursename.errors.required">Coursename is
                                  required</div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-12">
                            <div class="form-group">
                              <label class="control-label">Instructor Name</label>
                              <input type="text" class="form-control" formControlName="Instructor" name="Instructor"
                                id="Instructor">
                              <div *ngIf="submitted && f.Instructor.errors" class="invalid-feedback"
                                style="display: block;">
                                <div *ngIf="f.Instructor.errors.required">Instructor is
                                  required</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <div class="form-group">
                              <label class="control-label">Description</label>
                              <div class="form-group">
                                <!-- <div class="summernote">Hello Summernote
                                                                </div> -->
                                <!-- <ckeditor [editor]="editor"
                                                                    formControlName="Coursedescription"
                                                                    id="Coursedescription"></ckeditor> -->
                                <angular-editor formControlName="Coursedescription">
                                </angular-editor>
                                <div *ngIf="submitted && f.Coursedescription.errors" class="invalid-feedback"
                                  style="display: block;">
                                  <div *ngIf="f.Coursedescription.errors.required">
                                    Coursedescription is required</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button class="btn btn-warning" type="submit">Update
                          Detail</button>
                      </form>
                    </div>
                  </div><!-- /.modal-content -->
                </div><!-- /.modal-dialog -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

  </div> <!-- container-fluid -->
</div>
<!-- End Page-content -->
<div class="modal fade alert_popup" tabindex="-1" id="confirmodal" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- <div class="modal-header">
                <p> Do you want to delete these Courses?</p>
            </div> -->
      <div class="modal-body">
        <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
          <!-- <div class="sc-ion-alert-ios-backdrop"></div> -->
          <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
            <div class="alert-head sc-ion-alert-ios">
              <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete
                Course</h2>
            </div>
            <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you
              sure you want to delete?</div>
            <div class="alert-button-group sc-ion-alert-ios">
              <button type="button" class="alert-button"><span class="alert-button-inner sc-ion-alert-ios"
                  (click)="DeleteCourse(course_id,'confirm')">Yes</span>
              </button>
              <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
                  class="alert-button-inner sc-ion-alert-ios">No</span></button>
            </div>
          </div>
        </div>
        <!-- <button class="btn btn-light" data-dismiss="modal" aria-label="Close">Close</button>&nbsp;&nbsp;&nbsp;
                <button class="btn btn-success" (click)="DeleteCourse(course_id,'confirm')">Confirm</button> -->
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>