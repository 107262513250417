<div class="home-btn d-none d-sm-block">
    <a href="index.html"><i class="mdi mdi-home-variant h2 text-white"></i></a>
</div>
<div>
    <div class="container-fluid p-0">
        <div class="row no-gutters">
            <div class="col-lg-4">
                <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                    <div class="w-100">
                        <div class="row justify-content-center">
                            <div class="col-lg-9">
                                <div>
                                    <div class="text-center">
                                        <div>
                                            <a href="javascript:void();" class="logo"><img
                                                    src="assets/images/logo-light.png" height="35" alt="logo"></a>
                                        </div>
                                        <h4 class="font-size-18 mt-4">Welcome to Login</h4>
                                    </div>

                                    <div class="p-2 mt-3">
                                        <div *ngIf="errormsg" class="alert alert-danger" role="alert">
                                            {{errormsg}}
                                        </div>
                                        <form class="form-horizontal" [formGroup]="LoginForm" (ngSubmit)="login()">

                                            <div class="form-group auth-form-group-custom mb-4">
                                                <i class="ri-user-2-line auti-custom-input-icon"></i>
                                                <label for="username">Username</label>
                                                <input type="text" formControlName="Username" class="form-control"
                                                    id="username" placeholder="Enter username">

                                            </div>
                                            <div *ngIf="submitted && f.Username.errors" class="invalid-feedback"
                                                style="display: block;">
                                                <div *ngIf="f.Username.errors.required">Username is required</div>
                                            </div>

                                            <div class="form-group auth-form-group-custom mb-4">
                                                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                                                <label for="userpassword">Password</label>
                                                <input type="password" formControlName="Password" class="form-control"
                                                    id="userpassword" placeholder="Enter password">
                                                <div *ngIf="submitted && f.Password.errors" class="invalid-feedback"
                                                    style="display: block;">
                                                    <div *ngIf="f.Password.errors.required">Password is required</div>
                                                </div>
                                            </div>

                                            <!-- <div class="custom-control custom-checkbox" style="display: none;">
                                                <input type="checkbox" class="custom-control-input"
                                                    id="customControlInline">
                                                <label class="custom-control-label" for="customControlInline">Remember
                                                    me</label>
                                            </div> -->

                                            <div class="mt-4 text-center">
                                                <button class="btn btn-warning w-md waves-effect waves-light"
                                                    type="submit">Log In</button>
                                            </div>

                                            <div class="mt-4 text-center">
                                                <a href="javascript:void(0)" class="text-muted"
                                                    (click)="forgotpassword()"><i class="mdi mdi-lock mr-1"></i> Forgot
                                                    your password?</a>
                                                <div class="alert alert-success p-2 mt-2 mb-0 font-size-12" role="alert"
                                                    *ngIf="forgotpasswormsg">
                                                    An email has been sent to the registered email id for password
                                                    reset.
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div class="mt-3 text-center">
                                        <p>© 2020 IUT Crafted with <i class="mdi mdi-heart text-danger"></i> by

                                            <a href="https://greychaindesign.com/" style="color:#505d69;"
                                                target="_blank">Grey Chain</a>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="authentication-bg">
                    <div class="bg-overlay"></div>
                </div>
            </div>
        </div>
    </div>
</div>