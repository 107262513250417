import { Injectable } from '@angular/core';
import { endpoint } from '../../constants/endpoints.constants';
import { CommonService } from '../common/common.service';
import { EventsService } from '../core/events/events.service';
import { HttpWrapperService } from '../core/http/http-wrapper/http-wrapper.service';
import { StorageService } from '../core/storage/storage.service';
import { storageKeys } from '../../constants/storage-keys.constants';
import { eventKeys } from '../../constants/event-keys.constants';
import { UserData } from '../../components/admin/modals/usermodal/users.model';
import { Coursedata } from '../../components/admin/modals/coursemodal/course.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  Couesr_Id: string;
  constructor(private httpWrapperService: HttpWrapperService,
    private commonService: CommonService,
    private storage: StorageService,
    private events: EventsService) { }


  async getCourseList(page) {

    return this.httpWrapperService.get(endpoint.allcourse(page)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.userList) {
            }
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.status == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async getUserCourseList() {

    return this.httpWrapperService.get(endpoint.GetUserallcourse).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.userList) {
            }
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.status == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }
  async getActiveCourseList() {

    return this.httpWrapperService.get(endpoint.courseactive).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.userList) {
            }
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.status == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async deleteCourse(course_id: string) {

    let payload = {}
    return this.httpWrapperService.delete(endpoint.deletecourse(course_id)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }

  async EditCourse(payload: any) {

    return this.httpWrapperService.put(endpoint.editcourse(this.Couesr_Id), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      );
  }

  async Asigncourse(coursedata: any) {

    return this.httpWrapperService.post(endpoint.assigncourse, coursedata).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.events.publish(eventKeys.usercreated, '');
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }

  async ChangeCourseStatus(payload) {
    return this.httpWrapperService.put(endpoint.coursechangestatus(this.Couesr_Id), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      );
  }

  async CourseSearchFilter(payload) {

    return this.httpWrapperService.get(endpoint.searchchapter + '?search=' + payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.courseList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }
}
