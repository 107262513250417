import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { CommonService } from 'src/app/Services/common/common.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AngularEditorConfig } from '@kolkov/angular-editor';
declare var $: any;
@Component({
  selector: 'app-coursemodal',
  templateUrl: './coursemodal.component.html',
  styleUrls: ['./coursemodal.component.scss']
})
export class CoursemodalComponent implements OnInit {
  AddCourseForm: FormGroup;
  submitted = false;
  editor = ClassicEditor;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private commonSerivce: CommonService
  ) {

  }

  ngOnInit(): void {
    this.AddCourseForm = this.formBuilder.group({
      CourseImage: ['', Validators.required],
      Coursename: ['', Validators.required],
      Coursedescription: ['', Validators.required],
      instructor: ['', Validators.required],
    })
  }
  get f() { return this.AddCourseForm.controls; }

  fileToUpload: File = null;
  handleFileInput(files: FileList) {

    this.fileToUpload = files.item(0);
  }
  AddCourse() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.AddCourseForm.invalid) {
      return;
    }
    this.commonSerivce.presentSpinner();
    var frmData = new FormData();
    frmData.append("image", this.fileToUpload);
    frmData.append("courseName", this.AddCourseForm.value.Coursename);
    frmData.append("description", this.AddCourseForm.value.Coursedescription);
    frmData.append("instructor", this.AddCourseForm.value.instructor);

    this.auth.AddCourse(frmData).then(
      (res) => {
        this.commonSerivce.dismissSpinner();
        if (res) {
          if (res.code === 200) {
            this.AddCourseForm.reset();
            $('#add_detail').modal('hide');
            this.submitted = false;
            this.commonSerivce.presentSuccessToast('', res.message);

          }
        }
      }
    );
  }

}
