<div>
    <div class="container-fluid p-0">
        <div class="row no-gutters">
            <div class="col-lg-4">
                <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                    <div class="w-100">
                        <div class="row justify-content-center">
                            <div class="col-lg-9">
                                <div>
                                    <div class="text-center">
                                        <div>
                                            <a href="javascript:void(0)" class="logo"><img
                                                    src="assets/images/logo-light.png" height="35" alt="logo"></a>
                                        </div>

                                        <h4 class="font-size-18 mt-4">Forgot Password</h4>
                                    </div>

                                    <div class="p-2 mt-3">
                                        <form class="form-horizontal" [formGroup]="ResetPasswordForm">

                                            <div class="form-group auth-form-group-custom mb-4">
                                                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                                                <label for="userpassword">Password</label>
                                                <input type="password" class="form-control" id="userpassword"
                                                    placeholder="Enter password" formControlName="password">
                                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback"
                                                    style="display: block;">
                                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                                </div>
                                            </div>
                                            <div class="form-group auth-form-group-custom mb-4">
                                                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                                                <label for="userpassword">Password</label>
                                                <input type="password" class="form-control" id="userpassword"
                                                    placeholder="Confirm password" formControlName="confirmpassword">
                                                <div *ngIf="submitted && f.confirmpassword.errors"
                                                    class="invalid-feedback" style="display: block;">
                                                    <div *ngIf="f.confirmpassword.errors.required">Confirm password is
                                                        required</div>
                                                </div>
                                            </div>

                                            <div class="mt-4 text-center" (click)="ResetPassword()">
                                                <button class="btn btn-primary w-md waves-effect waves-light"
                                                    type="submit">Save</button>
                                            </div>
                                        </form>
                                    </div>

                                    <div class="mt-3 text-center">
                                        <p>Don't have an account ? <a href="javascript:void(0)"
                                                class="font-weight-medium text-primary" [routerLink]="['/']"> Log in
                                            </a> </p>
                                        <p>© 2020 Soum Crafted with <i class="mdi mdi-heart text-danger"></i> by Grey
                                            Chain</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="authentication-bg">
                    <div class="bg-overlay"></div>
                </div>
            </div>
        </div>
    </div>
</div>