<div class="page-content">
  <div class="container-fluid">

    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <div class="page-title-right page-title-right2">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item"><a (click)="goback()">Courses</a>
              </li>
              <li class="breadcrumb-item"><a href="javascript: void(0);" (click)="goback()">Chapters</a></li>
              <li class="breadcrumb-item active"><a href="javascript: void(0);" (click)="goback()"></a> Chapter Details
              </li>
            </ol>


          </div>

        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-3 chapter-detail-edit">
            <div class="section-nav-tabs">




              <!-- Nav tabs -->
              <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab" href="#basic-details" role="tab" aria-selected="true">
                    <span class="d-sm-block"> Basic Details</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#assessment-details" role="tab" aria-selected="false">
                    <span class="d-sm-block"> Assessment Details</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#resources-details" role="tab" aria-selected="false">
                    <span class="d-sm-block">Resources Details</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#videos-details" role="tab" aria-selected="false">
                    <span class="d-sm-block"> Videos</span>
                  </a>
                </li>

              </ul>

              <!-- Tab panes -->
              <div class="tab-content p-3 text-muted">
                <div class="tab-pane active" id="basic-details" role="tabpanel">
                  <div class="chapter-basic-details">
                    <form [formGroup]="BasicDetailForm">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="control-label">Upload Picture</label>
                            <div class="custom_file custom_img_input">
                              <input type="file" class="custom_file_input" formControlName="ChapterImage"
                                id="ChapterImage" (change)="ChapterImageOnchange($event.target.files)">
                              <div class="user_img"
                                style="background-color: rgb(245, 245, 245);background-image: url({{chapterimage}});">
                              </div>

                            </div>
                            <div *ngIf="submitted && f.ChapterImage.errors" class="invalid-feedback"
                              style="display: block;">
                              <div *ngIf="f.ChapterImage.errors.required">ChapterImage is required</div>
                            </div>
                          </div>

                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="control-label">Chapter Name</label>
                            <input type="text" formControlName="name" class="form-control" name="chapter-name"
                              id="chapter-name">
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label class="control-label">Description</label>
                            <!-- <div class="summernote">Hello Summernote</div> -->
                            <angular-editor formControlName="description">
                            </angular-editor>
                          </div>
                        </div>
                      </div>
                      <div class="chapter_button">
                        <!-- <button type="button" class="btn btn-danger waves-effect waves-light">
                          <i class="ri-close-line align-middle mr-2"></i> Cancel
                        </button> -->
                        <button (click)="EditBasicDetail()" type="button"
                          class="btn btn-warning waves-effect waves-light">
                          <!-- <i class="ri-check-line align-middle mr-2"></i> -->
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div class="tab-pane" id="assessment-details" role="tabpanel">
                  <div class="chapter-assessment-details">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="question-page-title mb-3">
                          <div class="list-title">
                            <h6 *ngIf="assessment.length > 0" class="mb-0">Question list</h6>
                            <h6 class=" mb-0" [hidden]="assessment?.length" style="color: #545a60;">No Records found
                            </h6>
                          </div>


                          <!-- <div [hidden]="assessment?.length" class="alert alert-warning" role="alert">
                            <h5 class="text-danger text-center">No Records found</h5>
                          </div> -->
                          <div class="page-title-right2">
                            <button type="button" class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
                              data-toggle="modal" data-target=".add_detail">Add</button>
                            <app-add-question-modal [chapterid]="chapterid">
                            </app-add-question-modal>

                          </div>

                        </div>

                        <div class="question-row" *ngFor="let assess of assessment;  let i = index">

                          <div class=" title-box">
                            <h5 class="question-title card-title"><span class="question-no card-title mr-2">Q
                                {{i+1}}.</span> <span class="q_title">{{assess.question}}
                              </span>
                              <input type="text"
                                class="form-control form-control-sm b-radius-0 ng-star-inserted q_title_input"
                                style="max-width:100%;display: none;">
                            </h5>
                            <div class="dataTables_length">
                              <span class="action_btn mr-2">
                                <button type="button" class="mr-2 text-primary btn_style" data-toggle="modal"
                                  data-target=".edit_detail" (click)="EditQuestionAnswer(assess)"><i
                                    class="mdi mdi-pencil font-size-18"></i></button>


                                <!-- <button type="button"
                                                                    class="mr-2 btn_success btn_style ng-star-inserted"><i
                                                                        class="mdi mdi-check-bold font-size-18"></i></button> -->
                                <a href="javascript:void(0);" class="text-danger" data-toggle="modal"
                                  data-placement="top" data-target="#confirmodal" title="" data-original-title="Delete"
                                  (click)="Opendialog(assess._id)"><i class="mdi mdi-trash-can font-size-18"></i></a>
                              </span>
                              <span class="marks">
                                <label>Marks <input type="text" class="form-control form-control-sm"></label>
                              </span>
                            </div>
                          </div>
                          <div class="question-box table-responsive">
                            <table class="table mb-0">
                              <thead class="thead-light">
                                <tr>
                                  <th>No.</th>
                                  <th>Answer</th>
                                  <th style="width: 120px;">Correct Answer
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let ans of assess.options; let in = index">
                                  <!-- correctAnswer == {{assess.correctAnswer}} -->

                                  <!-- {{ans|json}} -->
                                  <td data-title="No." class="choices">
                                    {{ans.optionNumber}}
                                  </td>
                                  <td class="answer" data-title="Answer">
                                    <span>{{ans.optionValue}}</span>
                                    <input type="text" class="form-control form-control-sm b-radius-0 ng-star-inserted"
                                      style="max-width: 120px;display: none;">
                                  </td>
                                  <td data-title="Correct Answer" *ngIf="assess.correctAnswer == ans.optionNumber">
                                    <span class="action-radio">
                                      <button type="button" class="mr-2 btn_success btn_style ng-star-inserted"><i
                                          class="mdi mdi-check-bold font-size-18"></i></button>
                                    </span>
                                  </td>
                                  <td data-title="Correct Answer" *ngIf="assess.correctAnswer != ans.optionNumber">

                                  </td>
                                </tr>

                              </tbody>
                            </table>
                          </div><!-- Question box-->
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div class="tab-pane" id="resources-details" role="tabpanel">
                  <div class="chapter-resources-details">
                    <div class="row">
                      <div class="col-lg-12 table-responsive">
                        <div class="question-page-title mb-3">
                          <h6 class="mb-0">Resources list</h6>
                          <div class="page-title-right2">
                            <button type="button" data-toggle="modal" data-target=".add_resources_detail"
                              class="btn btn-rounded btn-warning btn-w waves-effect waves-light"
                              (click)="EditResourceDetail('add','a')">Add</button>
                          </div>
                        </div>
                        <table class="table mb-0">
                          <thead class="thead-light">
                            <tr>
                              <th>No.</th>
                              <th>File Type</th>
                              <th>File Name </th>
                              <th>File Link</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr [hidden]="resourcelist?.length">
                              <th colspan="7">
                                <h6 class=" mb-0" style="color: #545a60;">No resources added</h6>

                              </th>
                            </tr>
                            <tr *ngFor="let item of resourcelist; let i = index">
                              <td scope="row" data-title="No.">{{i+1}}</td>
                              <td data-title="Type of File" *ngIf="item.type == 2">Custom
                                File</td>
                              <td data-title=" Type of File" *ngIf="item.type == 1">Video
                                Link</td>
                              <td data-title="Name of File ">{{item.title?item.title:''}}</td>
                              <td data-title="Link of File">
                                <a class="popup-youtube popup-file btn-text" href="{{item.file?item.file:''}}"
                                  target="_blank">View
                                  File</a>

                              </td>
                              <td data-title="Actions">
                                <button type="button" class="mr-2 text-primary btn_style" data-toggle="modal"
                                  data-target=".add_resources_detail" (click)="EditResourceDetail(item,'aa')"><i
                                    class="mdi mdi-pencil font-size-18"></i></button>

                                <a href="javascript:void(0);" class="text-danger" data-toggle="modal"
                                  data-placement="top" title="" data-original-title="Delete" data-target="#confirmodal1"
                                  data-placement="top" (click)="deleteresourcemodal(item)"><i
                                    class="mdi mdi-trash-can font-size-18"></i></a>
                                <!-- 

  <a href="javascript:void(0);" class="text-danger" data-toggle="modal" data-placement="top" data-target="#confirmodal"
    title="" data-original-title="Delete" (click)="Opendialog(assess._id)"><i
      class="mdi mdi-trash-can font-size-18"></i></a> -->

                              </td>
                            </tr>

                          </tbody>
                        </table>
                        <div class="modal fade add_resources_detail" tabindex="-1" role="dialog"
                          aria-labelledby="mySmallModalLabel" style="display: none;" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title mt-0" *ngIf="!ShowEditForm">Add Resources</h5>
                                <h5 class="modal-title mt-0" *ngIf="ShowEditForm">Edit Resources</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <div class="form-group field-repeater">
                                  <div class="repeater-box">
                                    <div class="row">
                                      <div class="col-lg-12">
                                        <label class="control-label">Select Option</label>
                                        <select class="form-control mb-2" (change)="SelectOption($event)"
                                          [(ngModel)]="selectfileopt">
                                          <option selected value="0">Custom File Upload
                                          </option>
                                          <!-- <option value="1">Youtube Link Add</option> -->
                                        </select>
                                        <div *ngIf="ShowCustomFileFeilds">
                                          <label class="control-label">File Name</label>
                                          <input type="text" class="form-control mb-2" name="file-name" id="file-name"
                                            placeholder="File Name" [(ngModel)]="customfilename">

                                          <div class="custom_file">
                                            <label class="control-label">Enter Link</label>
                                            <input type="file" class="custom_file_input" id="customFile"
                                              [(ngModel)]="customfile" (change)="handleFileInput($event)">
                                            <a *ngIf="viewcustomfile" href="{{viewcustomfile}}"
                                              class="viewfile mt-2">ViewFile</a>
                                            <!-- <iframe ng-src="{{viewcustomfile}}" width="800px" height="600px"></iframe> -->

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="repeater-box" *ngIf="ShowVideoFeilds">
                                    <div class="row">
                                      <div class="col-lg-12">
                                        <label class="control-label">File Name</label>
                                        <input type="text" class="form-control mb-2" name="file-name" id="file-name"
                                          placeholder="File Name" [(ngModel)]="videotitle">
                                        <label class="control-label">Enter Link</label>
                                        <input type="text" class="form-control" name="file-link" id="file-link"
                                          placeholder="Enter Link" [(ngModel)]="videolink">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="chapter_button" *ngIf="ShowCustomFileFeilds;else elseBlock">
                                  <!-- <button type="button" class="btn btn-danger waves-effect waves-light">
                                    <i class="ri-close-line align-middle mr-2"></i> Cancel
                                  </button> -->
                                  <button type="button" class="btn btn-warning waves-effect waves-light"
                                    (click)="SaveResource(e)"
                                    [disabled]="(customfilename == '') && (customfile == '') ">
                                    <!-- <i class="ri-check-line align-middle mr-2"></i> -->
                                    Save
                                  </button>
                                </div>
                                <ng-template #elseBlock>
                                  <div class="chapter_button">
                                    <!-- <button type="button" class="btn btn-danger waves-effect waves-light">
                                      <i class="ri-close-line align-middle mr-2"></i> Cancel
                                    </button> -->
                                    <button type="button" class="btn btn-warning waves-effect waves-light"
                                      (click)="SaveResource(e)" [disabled]="(videotitle == '') && (videolink == '') ">
                                      <!-- <i class="ri-check-line align-middle mr-2"></i> -->
                                      Save
                                    </button>
                                  </div>
                                </ng-template>

                              </div>
                            </div><!-- /.modal-content -->
                          </div><!-- /.modal-dialog -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="videos-details" role="tabpanel">
                  <div class="chapter-video-details">
                    <div class="row">
                      <div class="col-lg-12 table-responsive">
                        <div class="question-page-title mb-3">
                          <h6 class="mb-0">Videos list</h6>
                          <div class="page-title-right2">
                            <button type="button" data-toggle="modal" data-target=".add_Video_detail"
                              class="btn btn-rounded btn-warning btn-w waves-effect waves-light">Add </button>
                          </div>
                        </div>
                        <table class="table mb-0">
                          <thead class="thead-light">
                            <tr>
                              <th>No.</th>
                              <th>Video Title</th>
                              <th>Instructor Name</th>
                              <th>Video Link</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr [hidden]="videolist?.length">
                              <th colspan="7">
                                <h6 class=" mb-0">No videos added</h6>

                              </th>
                            </tr>
                            <tr *ngFor="let video of videolist; let i = index">
                              <td scope="row" data-title="No.">{{i+1}}</td>
                              <td data-title="Video Title">{{video.videoTitle?video.videoTitle:''}}</td>
                              <td data-title="Instructor Name">{{video.videoInstructor?video.videoInstructor:''}}</td>
                              <td data-title="Link of Video"> {{video.videoLink?video.videoLink:''}} </td>
                              <td data-title="Actions">
                                <button type="button" class="mr-2 text-primary btn_style" data-toggle="modal"
                                  data-target=".edit_video_detail" (click)="Editvideo(video)"><i
                                    class="mdi mdi-pencil font-size-18"></i></button>
                                <!-- <a href="javascript:void(0);" class="text-danger" data-toggle="tooltip"
                                  data-placement="top" title="" data-original-title="Delete"
                                  data-target="#videoconfirmodal" (click)="deleteVideomodal(video)"><i
                                    class="mdi mdi-trash-can font-size-18"></i></a> -->

                                <a href="javascript:void(0);" class="text-danger" data-toggle="modal"
                                  data-placement="top" title="" data-original-title="Delete"
                                  data-target="#videoconfirmodal" data-placement="top"
                                  (click)="deleteVideomodal(video)"><i class="mdi mdi-trash-can font-size-18"></i></a>

                              </td>
                            </tr>

                          </tbody>
                        </table>
                        <div class="modal fade edit_video_detail" tabindex="-1" role="dialog"
                          aria-labelledby="mySmallModalLabel" style="display: none;" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title mt-0">Edit Video(s)</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <form [formGroup]="EditVideoForm">
                                  <div class="form-group field-repeater">
                                    <!-- <label class="control-label">Videos</label> -->
                                    <div class="repeater-box">
                                      <div class="row">
                                        <div class="col-lg-12">
                                          <label class="control-label">Video Title</label>
                                          <input type="text" formControlName="videoTitle" class="form-control mb-2"
                                            name="videoTitle" id="videoTitle" placeholder="Video Title">
                                          <div *ngIf="titleerror" class="invalid-feedback" style="display: block;">
                                            {{titleerror}}</div>
                                          <label class="control-label">Instructor Name</label>
                                          <input type="text" class="form-control mb-2" name="videoInstructor"
                                            formControlName="videoInstructor" id="videoInstructor"
                                            placeholder="Instructor Name">
                                          <div *ngIf="titleerror" class="invalid-feedback" style="display: block;">
                                            {{Instructorerror}}</div>
                                          <label class="control-label">Enter Link</label>
                                          <input type="text" formControlName="videoLink" class="form-control"
                                            name="videoLink" id="videoLink" placeholder="Enter Link">
                                          <div *ngIf="titleerror" class="invalid-feedback" style="display: block;">
                                            {{videoLinkerror}}</div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="chapter_button">
                                    <!-- <button type="button" class="btn btn-danger waves-effect waves-light">
                                      <i class="ri-close-line align-middle mr-2"></i> Cancel
                                    </button> -->
                                    <button (click)="UpdateVideos()" type="button"
                                      class="btn btn-warning waves-effect waves-light">
                                      <!-- <i class="ri-check-line align-middle mr-2"></i> -->
                                      Update
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div><!-- /.modal-content -->
                          </div><!-- /.modal-dialog -->
                        </div>
                        <div class="modal fade add_Video_detail" tabindex="-1" role="dialog"
                          aria-labelledby="mySmallModalLabel" style="display: none;" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title mt-0">Add Video(s)</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <form [formGroup]="SaveVideoForm">
                                  <div class="form-group field-repeater">
                                    <!-- <label class="control-label">Videos</label> -->
                                    <div class="repeater-box">
                                      <div class="row" formArrayName="videos"
                                        *ngFor="let field of SaveVideoForm.get('videos')['controls']; let i = index;">
                                        <div class="col-lg-12" [formGroupName]="i">
                                          <label class="control-label">Video Title</label>
                                          <input type="text" formControlName="videoTitle" class="form-control mb-2"
                                            name="videoTitle" id="videoTitle" placeholder="">
                                          <div *ngIf="titleerror" class="invalid-feedback" style="display: block;">
                                            {{titleerror}}</div>
                                          <label class="control-label">Instructor Name</label>
                                          <input type="text" class="form-control mb-2" name="videoInstructor"
                                            formControlName="videoInstructor" id="videoInstructor" placeholder="">
                                          <div *ngIf="Instructorerror" class="invalid-feedback" style="display: block;">
                                            {{Instructorerror}}</div>
                                          <label class="control-label">Enter Link</label>
                                          <input type="text" formControlName="videoLink" class="form-control"
                                            name="videoLink" id="videoLink" placeholder="">
                                          <div *ngIf="videoLinkerror" class="invalid-feedback" style="display: block;">
                                            {{videoLinkerror}}</div>
                                        </div>
                                        <a class="text-danger ml-3" (click)="removeVideo(i)"><i
                                            class="mdi mdi-trash-can font-size-18"></i></a>

                                      </div>
                                      <a class="icon-plus ml-3" (click)="AddVideo()"><i class="fas fa-plus"></i></a>
                                    </div>
                                  </div>
                                  <div class="chapter_button">
                                    <button (click)="AddVideos()" type="button"
                                      class="btn btn-warning waves-effect waves-light">
                                      <!-- <i class="ri-check-line align-middle mr-2"></i> -->
                                      Save
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div><!-- /.modal-content -->
                          </div><!-- /.modal-dialog -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

  </div> <!-- container-fluid -->
</div>

<div class="modal fade edit_detail" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title mt-0">Edit Question </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="EditQuestionForm">
          <div class="form-group">
            <label class="control-label">Question</label>
            <input type="text" class="form-control form-control-sm b-radius-0" [(ngModel)]="editquestion"
              formControlName="question1">
            <div *ngIf="submitted && f1.question1.errors" class="invalid-feedback" style="display: block;">
              <div *ngIf="f1.question1.errors.required">Question is required</div>
            </div>
          </div>
          <div id="answer" class="form-group">
            <label class="control-label width-full">Answer
              <span class="float-right">Mark Correct Option</span></label>
            <div class="form-group add-answer answer_row" formArrayName="options"
              *ngFor="let field of EditQuestionForm.get('options')['controls']; let i = index;">
              <div [formGroupName]="i">
                <input type="text" class="form-control form-control-sm b-radius-0 input-answer"
                  formControlName="answer">
                <!-- <input type="radio" formControlName="optradio"
                                    class="form-control form-control-sm b-radius-0" value="{{i+1}}" name="optradio"> -->

                <label class="radio-box"><input type="radio" formControlName="optradio" (click)="ChooseCorrectAns(i+1)"
                    value="{{i+1}}" name="optradio"><span>radio</span></label>
                <a class="text-danger ml-3" (click)="removefeilds(i)"><i class="mdi mdi-trash-can font-size-18"></i></a>

              </div>
              <!-- <button (click)="removefeilds(i)" id="remove0" class="btn btn-danger remove-me float-left">Remove</button> -->
            </div>
            <div class="form-group add-answer  mb-0 ">
              <!-- <button id="add-more" name="add-more" class="btn btn-warning align-items-center float-left"
                (click)="addAnswer()">Add
                More</button> -->
              <a class="icon-plus ml-3 float-right" (click)="addAnswer()"> <i class="fas fa-plus"></i></a>
            </div>
            <!-- <div class="form-group add-answer">
                            <input type="text" class="form-control form-control-sm b-radius-0">
                            <button id="add-more" name="add-more" class="btn btn-warning align-items-center">Add
                                More</button>
                        </div> -->
          </div>
          <button class="btn btn-warning" type="submit" (click)="UpdateAssessment()">Update</button>
        </form>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div class="modal fade alert_popup" tabindex="-1" id="confirmodal" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">

        <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
          <!-- <div class="sc-ion-alert-ios-backdrop"></div> -->
          <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
            <div class="alert-head sc-ion-alert-ios">
              <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete
                Assessment</h2>
            </div>
            <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you
              sure you want to delete?</div>
            <div class="alert-button-group sc-ion-alert-ios">
              <button type="button" class="alert-button"><span class="alert-button-inner sc-ion-alert-ios"
                  (click)="DeleteAssessment(assesmentid)">Yes</span>
              </button>
              <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
                  class="alert-button-inner sc-ion-alert-ios">No</span></button>
            </div>
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
<div class="modal fade alert_popup" tabindex="-1" id="confirmodal1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">

        <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
          <!-- <div class="sc-ion-alert-ios-backdrop"></div> -->
          <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
            <div class="alert-head sc-ion-alert-ios">
              <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete
                Resource</h2>
            </div>
            <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you
              sure you want to delete?</div>
            <div class="alert-button-group sc-ion-alert-ios">
              <button type="button" class="alert-button"><span class="alert-button-inner sc-ion-alert-ios"
                  (click)="DeleteResource(resourceId)">Yes</span>
              </button>
              <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
                  class="alert-button-inner sc-ion-alert-ios">No</span></button>
            </div>
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
<div class="modal fade alert_popup" tabindex="-1" id="videoconfirmodal" role="dialog"
  aria-labelledby="mySmallModalLabel" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">

        <div class="sc-ion-alert-ios-h sc-ion-alert-ios-s">
          <!-- <div class="sc-ion-alert-ios-backdrop"></div> -->
          <div class="alert-wrapper ion-overlay-wrapper sc-ion-alert-ios">
            <div class="alert-head sc-ion-alert-ios">
              <h2 id="alert-4-hdr" class="alert-title sc-ion-alert-ios">Delete
                Video</h2>
            </div>
            <div id="alert-4-msg" class="alert-message sc-ion-alert-ios">Are you
              sure you want to delete?</div>
            <div class="alert-button-group sc-ion-alert-ios">
              <button type="button" class="alert-button"><span class="alert-button-inner sc-ion-alert-ios"
                  (click)="DeleteVideo(videoid)">Yes</span>
              </button>
              <button type="button" class="alert-button" tabindex="0" data-dismiss="modal" aria-label="Close"><span
                  class="alert-button-inner sc-ion-alert-ios">No</span></button>
            </div>
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>