import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { eventKeys } from 'src/app/constants/event-keys.constants';
import { ChapterserviceService } from 'src/app/Services/chapterservice/chapterservice.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { EventsService } from 'src/app/Services/core/events/events.service';
import { Location } from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-chapter-details',
  templateUrl: './chapter-details.component.html',
  styleUrls: ['./chapter-details.component.scss']
})
export class ChapterDetailsComponent implements OnInit {
  @Input() users: {}

  chapterid: any;
  assessment: any = [];
  public EditQuestionForm: FormGroup;
  SaveVideoForm: FormGroup;
  BasicDetailForm: FormGroup;
  EditVideoForm: FormGroup;
  chapterdetailobj: any = {}
  chapterobj: any = {};
  ShowVideoFeilds: boolean;
  ShowCustomFileFeilds: boolean = true;
  customfile: any = ''
  customfilename: any = ''
  videotitle: any = ''
  videolink: any = ''
  submitted = false;
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private events: EventsService,
    private chapterservice: ChapterserviceService,
    private commonService: CommonService,
    private router: Router,
    private location: Location
  ) {

    // this.chapterdetailobj = this.chapterservice.getchaapterobj();
    this.activatedRoute.queryParams.subscribe(params => {
      this.chapterid = params.chapterid;
    });
    this.events.subscribe(eventKeys.usercreated, () => {
      this.GetAllSingleassessment();
    })
  }
  goback() {
    this.location.back();
  }
  ngOnInit(): void {
    this.GetAllSingleassessment();
    this.GetChaperById();
    this.GetResourceList();
    this.GetChapterVideoList();

    this.EditQuestionForm = this.fb.group({
      question1: ['', Validators.required],
      options: this.fb.array([
        this.createFormrGroupValue()
      ])
    });

    this.BasicDetailForm = this.fb.group({
      ChapterImage: ['', ''],
      name: ['', Validators.required],
      description: ['', Validators.required],

    });

    this.EditVideoForm = this.fb.group({
      videoTitle: ['', Validators.required],
      videoInstructor: ['', Validators.required],
      videoLink: ['', Validators.required],

    });

    this.SaveVideoForm = this.fb.group({
      videos: this.fb.array([
        this.createVideoGroup()
      ])
    });


  }
  get f1() { return this.EditQuestionForm.controls; }

  get FormArrayvalue() {
    return (<FormArray>this.EditQuestionForm.get('options'));
  }
  get VideoArrayvalue() {
    return (<FormArray>this.SaveVideoForm.get('videos'));
  }
  get f() { return this.SaveVideoForm.controls; }

  createFormrGroupValue() {
    return this.fb.group({

      answer: this.fb.control('', Validators.required),
      optradio: this.fb.control(''),

    });
  }

  createVideoGroup() {
    return this.fb.group({

      videoTitle: this.fb.control('', Validators.required),
      videoInstructor: this.fb.control('', Validators.required),
      videoLink: this.fb.control('', Validators.required),

    });
  }

  AddVideo() {
    this.VideoArrayvalue.push(this.createVideoGroup());
  }
  addAnswer() {

    // this.createFormrGroupValue();
    this.FormArrayvalue.push(this.createFormrGroupValue());

  }
  removefeilds(index) {
    this.FormArrayvalue.removeAt(index);
  }

  removeVideo(index) {
    this.VideoArrayvalue.removeAt(index);
  }

  bins: any = [];
  qus: any;
  Question: any;
  assessmentId: any;
  EditQuestionAnswer(assessmentdata) {
    debugger
    this.assessmentId = assessmentdata._id;
    this.qus = assessmentdata.question;
    this.EditQuestionForm.controls['question1'].setValue(assessmentdata.question);
    var UpdateEditQuestionForm = this.EditQuestionForm.controls['question1'];
    this.EditQuestionForm = this.setUpForm(assessmentdata.correctAnswer, assessmentdata.options);
    this.EditQuestionForm.controls.question1 = UpdateEditQuestionForm;
  }

  setUpForm(qus: any, bins: any[]) {

    return new FormGroup({
      options: new FormArray(bins.map((bin) => this.createBin(qus, bin)))
    });
  }
  createBin(qus: any, bin: any) {
    return new FormGroup({
      answer: new FormControl(bin.optionValue || ''),
      optradio: new FormControl(qus || ''),
    })
  }
  CorrectAns: any;
  ChooseCorrectAns(ans) {
    this.CorrectAns = ans;
  }
  items: any = [];
  editquestion: any;
  UpdateAssessment() {
    debugger
    this.submitted = true;
    if (this.editquestion == '') {
      return
    }
    for (var i = 0; i < this.FormArrayvalue.value.length; i++) {
      if (this.FormArrayvalue.value[i].answer == '') {
        this.commonService.dismissSpinner();
        this.commonService.presentErrorToast('', 'Answer feild(s) are required');
        this.items = [];
        return
      }
      this.commonService.presentSpinner();
      var obj = { optionNumber: '', optionValue: '' };
      var arrIndex;
      // this.items[i + 1] = this.FormArrayvalue.value[i].answer;
      arrIndex = i + 1
      obj.optionNumber = arrIndex.toString();
      obj.optionValue = this.FormArrayvalue.value[i].answer;
      this.items.push(obj);
      if (this.CorrectAns != null && this.CorrectAns == this.FormArrayvalue.value[i].optradio) {
        var correctAnswer = this.FormArrayvalue.value[i].optradio;
      } else if (this.CorrectAns == undefined) {
        var correctAnswer = this.FormArrayvalue.value[i].optradio;
      }
    }
    let postdata = {
      'chapterId': this.chapterid,
      'question': this.editquestion,
      'options': this.items,
      'correctAnswer': correctAnswer
    }

    this.chapterservice.assessmentid = this.assessmentId;
    this.chapterservice.Editassessment(postdata).then(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.GetAllSingleassessment();
            this.commonService.dismissSpinner();
            this.EditQuestionForm.reset();
            this.items = [];
            $('.edit_detail').modal('hide');
            this.commonService.presentSuccessToast('', res.message);
          } else {
            this.commonService.dismissSpinner();
          }
        } else {
          this.items = [];
        }
      }
    )
  }

  GetAllSingleassessment() {
    this.commonService.presentSpinner();
    this.chapterservice.chapterid = this.chapterid;
    this.chapterservice.getChapterassesment().then(
      (res) => {
        if (res) {
          if (res.code == 200) {
            res.chapterData.assesment.forEach(e => {
              var obj = e.options
              const mapped = Object.keys(obj).map(key => ({ type: key, value: obj[key] }));
              e['option2'] = mapped
            });
            this.assessment = res.chapterData.assesment;
            this.commonService.dismissSpinner();
          } else if (res.code == 400) {
            this.commonService.dismissSpinner();
          }
        } else {

        }

      }
    )
  }
  assesmentid: any;
  Opendialog(item) {

    this.assesmentid = item;
  }
  DeleteAssessment(id) {

    this.commonService.presentSpinner();
    this.chapterservice.chapterid = this.chapterid;
    this.chapterservice.Deleteassessment(id).then(
      (res: any) => {
        this.commonService.dismissSpinner();
        if (res && res.code === 200) {
          this.GetAllSingleassessment();
          $('#confirmodal').modal('hide');
          this.commonService.presentSuccessToast('', res.message);

        }
      }
    )
  }

  titleerror: string = '';
  Instructorerror: string = '';
  videoLinkerror: string = '';
  AddVideos() {

    // this.submitted = true;
    // if (this.SaveVideoForm.invalid) {
    //   return
    // }
    // let postdata = {
    //   'videos': this.SaveVideoForm.value
    // }
    //  && this.VideoArrayvalue.value[i].videoInstructor == '' && this.VideoArrayvalue.value[i].videoLink == ''
    for (var i = 0; i < this.VideoArrayvalue.value.length; i++) {
      if (this.VideoArrayvalue.value[i].videoTitle == '') {
        this.titleerror = "Video title is required."
        return
      }
      if (this.VideoArrayvalue.value[i].videoInstructor == '') {
        this.Instructorerror = "Video instructor is required."
        return
      }

      if (this.VideoArrayvalue.value[i].videoLink == '') {
        this.videoLinkerror = "Video linkerror is required."
        return
      }


    }
    this.commonService.presentSpinner();

    this.chapterservice.chapterid = this.chapterid;
    this.chapterservice.AddVideos(this.SaveVideoForm.value).then(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.commonService.dismissSpinner();
            this.SaveVideoForm.reset();
            $('.add_Video_detail').modal('hide');
            this.GetChapterVideoList();
            this.titleerror = '';
            this.Instructorerror = '';
            this.videoLinkerror = '';

            this.commonService.presentSuccessToast('', res.message);
          } else {
            this.commonService.dismissSpinner();
          }
        }
      })
  }

  chapterdata: any;
  chapterimage: any;
  GetChaperById() {
    this.commonService.presentSpinner();
    this.chapterservice.chapterid = this.chapterid;
    this.chapterservice.getChapterById().then(
      (res) => {
        if (res) {
          if (res.code == 200) {
            this.chapterdata = res.chapterData;
            this.chapterimage = this.chapterdata.image;

            this.BasicDetailForm.patchValue({
              name: this.chapterdata.chapterName,
              description: this.chapterdata.description,
            });
            this.commonService.dismissSpinner();
          } else if (res.code == 400) {
            this.commonService.dismissSpinner();
            this.commonService.presentErrorToast('', 'No records found');
          }
        } else {

        }

      }
    )
  }
  capterImageUpload: File = null;
  ChapterImageOnchange(files: FileList) {
    this.capterImageUpload = files.item(0);
  }
  EditBasicDetail() {
    debugger
    if (this.BasicDetailForm.invalid) {
      return
    }
    this.commonService.presentSpinner();

    // let postdata = {
    //   'chapterName': this.BasicDetailForm.value.name,
    //   'description': this.BasicDetailForm.value.description,
    // }
    var frmData = new FormData();
    frmData.append("image", this.capterImageUpload);
    frmData.append("description", this.BasicDetailForm.value.description);
    frmData.append("chapterName", this.BasicDetailForm.value.name);
    this.chapterservice.chapterid = this.chapterid;

    this.chapterservice.EditChapterDetail(frmData).then(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.GetChaperById()
            this.commonService.dismissSpinner();
            this.commonService.presentSuccessToast('', res.message);
          } else {
            this.commonService.dismissSpinner();
          }
        }
      }

    )
  }
  e: any;
  SelectOption(event) {
    this.e = event.target.value;
    if (event.target.value == '1') {
      this.ShowVideoFeilds = true;
      this.ShowCustomFileFeilds = false;
    } else {
      this.ShowVideoFeilds = false;
      this.ShowCustomFileFeilds = true;
    }
  }
  fileToUpload: File = null;
  currentFile: any = [];
  imgtype: any;
  imageErrMsg: any = '';
  handleFileInput(event) {
    debugger
    this.imageErrMsg = ''
    var Filetype = event.target.files;
    var file: File = event.target.files[0];
    this.currentFile = file;
    var imagetype = this.currentFile.type.split('/');
    let ValidImageExt = imagetype[1];
    if (ValidImageExt == 'pdf') {
      this.fileToUpload = this.currentFile;
    } else {
      this.imageErrMsg = "Only pdf format is allowed";
      this.commonService.presentErrorToast('', this.imageErrMsg);
    }
    // this.fileToUpload = files.item(0);


  }

  ShowEditForm: boolean;
  resourceId: any;
  selectfileopt: string = '';
  viewcustomfile: any;
  fileURL: any;
  EditResourceDetail(item, a) {


    this.resourceId = item.resourceId;

    if (item == 'add') {
      this.customfilename = '';
      this.customfile = '';
      this.videotitle = '';
      this.videolink = '';
      this.ShowVideoFeilds = false;
      this.ShowCustomFileFeilds = true;
      this.ShowEditForm = false;
      this.selectfileopt = '0';
      this.viewcustomfile = ''

    } else {
      // var file = new Blob([this.viewcustomfile]);
      this.viewcustomfile = item.file;
      // this.fileURL = URL.createObjectURL(file);
      this.fileToUpload = null
      if (item.type == '1') {
        this.ShowEditForm = true;

        this.selectfileopt = '1';
        this.ShowVideoFeilds = true;
        this.ShowCustomFileFeilds = false;
        this.videotitle = item.title;
        this.videolink = item.file;
      } else if (item.type == '2') {
        this.ShowEditForm = true;
        this.selectfileopt = '0';
        this.ShowCustomFileFeilds = true;
        this.ShowVideoFeilds = false;
        this.customfilename = item.title;
        // this.customfile = item.file

      }
    }
  }

  SaveResource(en) {
    debugger
    this.commonService.presentSpinner();
    var frmData;
    if (this.imageErrMsg != '') {
      this.commonService.presentErrorToast('', this.imageErrMsg);
      this.commonService.dismissSpinner();
      return
    }
    if (this.resourceId) {
      if (en == undefined || en == '0') {
        frmData = new FormData();
        frmData.append("resourceType", 'file');
        frmData.append("title", this.customfilename);
        if (this.fileToUpload) {
          frmData.append("file", this.fileToUpload);
        }

        frmData.append("resourceId", this.resourceId);
      } else if (en == '1') {
        frmData = new FormData();
        frmData.append("resourceType", 'link');
        frmData.append("title", this.videotitle);
        frmData.append("link", this.videolink);
        frmData.append("resourceId", this.resourceId);
      }

    } else {
      this.viewcustomfile = '';
      if (en == undefined || en == '0') {
        frmData = new FormData();
        frmData.append("resourceType", 'file');
        frmData.append("title", this.customfilename);
        frmData.append("file", this.fileToUpload);
      } else if (en == '1') {
        frmData = new FormData();
        frmData.append("resourceType", 'link');
        frmData.append("title", this.videotitle);
        frmData.append("link", this.videolink);
      }

    }

    this.chapterservice.chapterid = this.chapterid;
    this.chapterservice.Addresource(frmData).then(
      (res) => {
        if (res) {
          if (res.code === 200) {
            $('.add_resources_detail').modal('hide');
            this.customfilename = '';
            this.customfile = '';
            this.videotitle = '';
            this.videolink = '';
            this.GetResourceList();


            this.commonService.presentSuccessToast('', res.message);
            this.commonService.dismissSpinner();

          } else {
            this.commonService.dismissSpinner();
          }
        }
      })


  }

  resourcelist: any = [];
  GetResourceList() {

    this.commonService.presentSpinner();
    this.chapterservice.chapterid = this.chapterid;
    this.chapterservice.getChapterResource().then(
      (res) => {
        if (res) {
          if (res.code == 200) {
            if (res.resourceList != null) {
              this.resourcelist = res.resourceList;
              this.commonService.dismissSpinner();
            }

          } else if (res.code == 400) {
            this.commonService.dismissSpinner();
            this.commonService.presentErrorToast('', 'No records found');
          }
        } else {

        }

      }
    )

  }

  videolist: any = [];
  GetChapterVideoList() {

    this.commonService.presentSpinner();
    this.chapterservice.chapterid = this.chapterid;
    this.chapterservice.getChaptervideo().then(
      (res) => {
        if (res) {
          if (res.code == 200) {
            if (res.videoList != null) {
              this.videolist = res.videoList;
              this.commonService.dismissSpinner();
            }

          } else if (res.code == 400) {
            this.commonService.dismissSpinner();
            this.commonService.presentErrorToast('', 'No records found');
          }
        } else {

        }

      }
    )
  }
  vid: any;
  Editvideo(formData) {

    this.vid = formData.videoId;
    this.EditVideoForm.patchValue({
      videoTitle: formData.videoTitle,
      videoInstructor: formData.videoInstructor,
      videoLink: formData.videoLink,
    });
  }
  UpdateVideos() {

    if (this.EditVideoForm.value.videoTitle == '') {
      this.titleerror = "Video title is required."
      return
    }
    if (this.EditVideoForm.value.videoInstructor == '') {
      this.Instructorerror = "Video instructor is required."
      return
    }

    if (this.EditVideoForm.value.videoLink == '') {
      this.videoLinkerror = "Video linkerror is required."
      return
    }




    this.commonService.presentSpinner();

    if (this.EditVideoForm.invalid) {
      return
    }
    this.chapterservice.chapterid = this.chapterid;
    let postdata = {

      "videoTitle": this.EditVideoForm.value.videoTitle,
      "videoLink": this.EditVideoForm.value.videoLink,
      "videoInstructor": this.EditVideoForm.value.videoInstructor,
      "videoId": this.vid

    }

    this.chapterservice.EditVideo(postdata).then(
      (res) => {
        if (res) {
          if (res.code === 200) {
            $('.edit_video_detail').modal('hide');

            this.commonService.dismissSpinner();
            this.EditVideoForm.reset();
            this.GetChapterVideoList();
            this.titleerror = '';
            this.Instructorerror = '';
            this.videoLinkerror = '';

            this.commonService.presentSuccessToast('', res.message);
          } else {
            this.commonService.dismissSpinner();
          }
        }
      })
  }


  deleteresourcemodal(item) {

    this.resourceId = item.resourceId;


  }
  DeleteResource(resourceid) {

    this.commonService.presentSpinner();
    this.chapterservice.chapterid = this.chapterid;
    // let id = { "resourceId": resourceid }
    this.chapterservice.DeleteResource(resourceid).then(
      (res: any) => {
        this.commonService.dismissSpinner();
        if (res && res.code === 200) {
          this.GetResourceList();
          $('#confirmodal1').modal('hide');
          this.commonService.presentSuccessToast('', res.message);

        }
      }
    )
  }

  videoid: any;
  deleteVideomodal(video) {

    this.videoid = video.videoId;
  }

  DeleteVideo(id) {

    this.commonService.presentSpinner();
    this.chapterservice.chapterid = this.chapterid;
    // let id = { "resourceId": resourceid }
    this.chapterservice.Deletevideo(id).then(
      (res: any) => {
        this.commonService.dismissSpinner();
        if (res && res.code === 200) {
          this.GetChapterVideoList();
          $('#videoconfirmodal').modal('hide');
          this.commonService.presentSuccessToast('', res.message);

        }
      }
    )
  }





}
