<!-- Begin page -->
<div id="layout-wrapper" [ngClass]="{'sidebar-enable vertical-collpsed': sidePanelEnabled}">

  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="index.html" class="logo logo-dark">
            <span class="logo-sm">
              <img src="assets/images/logo-sm-dark.png" alt="" height="22">
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-dark.png" alt="" height="20">
            </span>
          </a>

          <a href="index.html" class="logo logo-light">
            <span class="logo-sm">
              <img src="assets/images/logo-sm-light.png" alt="" height="22">
            </span>
            <span class="logo-lg">
              <img src="assets/images/logo-light.png" alt="" height="20">
            </span>
          </a>
        </div>

        <button type="button" (click)="enableSidePanel()" class="btn btn-sm px-3 font-size-24 header-item waves-effect"
          id="vertical-menu-btn">
          <i class="ri-menu-2-line align-middle"></i>
        </button>

        <!-- App Search-->
        <!-- <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input type="text" class="form-control" placeholder="Search...">
            <span class="ri-search-line"></span>
          </div>
        </form> -->


      </div>

      <div class="d-flex">

        <div class="dropdown d-inline-block d-lg-none ml-2">
          <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="ri-search-line"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
            aria-labelledby="page-header-search-dropdown">

            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Search ...">
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="submit"><i class="ri-search-line"></i></button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>


        <!-- <div class="dropdown d-none d-lg-inline-block ml-1">
          <button type="button" class="btn header-item noti-icon waves-effect" data-toggle="fullscreen">
            <i class="ri-fullscreen-line"></i>
          </button>
        </div> -->
        <div class="dropdown d-inline-block user-dropdown">
          <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <!-- <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-2.jpg" alt="Header Avatar"> -->
            <span class="d-xl-inline-block ml-1">Welcome {{AdminName}}</span>
            <i class="mdi mdi-chevron-down d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <!-- item-->
            <!-- <a class="dropdown-item" href="javascript:void();"><i class="ri-user-line align-middle mr-1"></i>
              Profile</a> -->
            <a href="javascript:void()" class="dropdown-item" data-toggle="modal" data-target=".change_password"><i
                class="mdi mdi-lock-reset align-middle mr-1"></i> Change
              Password</a>
            <!-- <a class="dropdown-item" href="forgot-password.html"><i class="mdi mdi-lock-reset align-middle mr-1"></i>
              Forgot
              Password</a> -->
            <!-- <a class="dropdown-item" href="javascript:void();"><i class="ri-wallet-2-line align-middle mr-1"></i> My
              Wallet</a> -->
            <!-- <a class="dropdown-item d-block" href="#"><span class="badge badge-success float-right mt-1">11</span><i
                class="ri-settings-2-line align-middle mr-1"></i> Settings</a> -->
            <!-- <a class="dropdown-item" href="#"><i class="ri-lock-unlock-line align-middle mr-1"></i> Lock screen</a> -->
            <div class="dropdown-divider"></div>
            <a class="dropdown-item text-danger" href="javascript:void(0)" (click)="logout()"><i
                class="ri-shut-down-line align-middle mr-1 text-danger"></i> Logout</a>
          </div>
        </div>

        <!-- <div class="dropdown d-inline-block">
          <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect">
            <i class="ri-settings-2-line"></i>
          </button>
        </div> -->

      </div>
    </div>
  </header>
  <div class="modal fade change_password show" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title mt-0">Change Password</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="form-horizontal" [formGroup]="ChangePasswordForm" (ngSubmit)="changePassword()">
            <div class="form-group auth-form-group-custom mb-4">
              <i class="ri-lock-2-line auti-custom-input-icon"></i>
              <label for="userpassword">Old Password</label>
              <input type="password" class="form-control" id="OldPassword" placeholder="Enter old password"
                formControlName="OldPassword">
              <div *ngIf="submitted && f.OldPassword.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.OldPassword.errors.required">Old password is required</div>
              </div>
            </div>

            <div class="form-group auth-form-group-custom mb-4">
              <i class="ri-lock-2-line auti-custom-input-icon"></i>
              <label for="userpassword">New Password</label>
              <input type="password" class="form-control" id="userpassword" placeholder="Enter new password"
                formControlName="NewPassword">
              <div *ngIf="submitted && f.NewPassword.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.NewPassword.errors.required">New password is required</div>
                <div *ngIf="f.NewPassword.errors.minlength">Password must be at least 6 characters</div>
              </div>
            </div>
            <div class="form-group auth-form-group-custom mb-4">
              <i class="ri-lock-2-line auti-custom-input-icon"></i>
              <label for="userpassword">Confirm Password</label>
              <input type="password" class="form-control" id="userpassword" placeholder="Enter Confirm password"
                formControlName="confirmPassword">
              <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback" style="display: block;">
                <div *ngIf="f.confirmPassword.errors.required">Confirm password is required</div>
                <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
              </div>
            </div>

            <div class="mt-4">
              <button class="btn btn-primary w-md waves-effect waves-light" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">

    <div data-simplebar class="h-100">

      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu">
          <li class="menu-title">Menu</li>

          <!-- <li>
            <a [routerLink]="['/admin/dashboard']" class="waves-effect">
              <i class="ri-dashboard-line"></i><span class="badge badge-pill badge-success float-right">0</span>
              <span>Dashboard</span>
            </a>
          </li> -->

          <li>
            <a [routerLink]="['/admin/subscribers']" class=" waves-effect">
              <i class="ri-file-list-line"></i>
              <span>Subscribers</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/admin/courses']" class=" waves-effect">
              <i class="ri-file-list-line"></i>
              <span>Courses</span>
            </a>
          </li>
          <!-- <li>
            <a [routerLink]="['/admin/assessments']" class=" waves-effect">
              <i class="ri-file-list-line"></i>
              <span>Assessments</span>
            </a>
          </li> -->
        </ul>
      </div>
      <!-- Sidebar -->
    </div>
  </div>
  <!-- Left Sidebar End -->

  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->
  <div class="main-content">

    <router-outlet></router-outlet>
    <!-- End Page-content -->

    <footer class="footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <script>
              document.write(new Date().getFullYear());</script> © IUT.
          </div>
          <div class="col-sm-6">
            <div class="text-sm-right d-none d-sm-block">
              Crafted with <i class="mdi mdi-heart text-danger"></i> by <a href="https://greychaindesign.com/"
                style="color:#505d69;" target="_blank">Grey Chain</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
  <!-- end main content-->

</div>
<!-- END layout-wrapper -->

<!-- Right Sidebar -->
<div class="right-bar">
  <div data-simplebar class="h-100">
    <div class="rightbar-title px-3 py-4">
      <a href="javascript:void(0);" class="right-bar-toggle float-right">
        <i class="mdi mdi-close noti-icon"></i>
      </a>
      <h5 class="m-0">Settings</h5>
    </div>

    <!-- Settings -->
    <hr class="mt-0" />
    <h6 class="text-center mb-0">Choose Layouts</h6>

    <div class="p-4">
      <div class="mb-2">
        <img src="assets/images/layouts/layout-1.jpg" class="img-fluid img-thumbnail" alt="">
      </div>
      <div class="custom-control custom-switch mb-3">
        <input type="checkbox" class="custom-control-input theme-choice" id="light-mode-switch" checked />
        <label class="custom-control-label" for="light-mode-switch">Light Mode</label>
      </div>

      <div class="mb-2">
        <img src="assets/images/layouts/layout-2.jpg" class="img-fluid img-thumbnail" alt="">
      </div>
      <div class="custom-control custom-switch mb-3">
        <input type="checkbox" class="custom-control-input theme-choice" id="dark-mode-switch"
          data-bsStyle="assets/css/bootstrap-dark.min.css" data-appStyle="assets/css/app-dark.min.css" />
        <label class="custom-control-label" for="dark-mode-switch">Dark Mode</label>
      </div>

      <div class="mb-2">
        <img src="assets/images/layouts/layout-3.jpg" class="img-fluid img-thumbnail" alt="">
      </div>
      <div class="custom-control custom-switch mb-5">
        <input type="checkbox" class="custom-control-input theme-choice" id="rtl-mode-switch"
          data-appStyle="assets/css/app-rtl.min.css" />
        <label class="custom-control-label" for="rtl-mode-switch">RTL Mode</label>
      </div>


    </div>

  </div> <!-- end slimscroll-menu-->
</div>