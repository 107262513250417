import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { eventKeys } from 'src/app/constants/event-keys.constants';
import { ChapterserviceService } from 'src/app/Services/chapterservice/chapterservice.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { EventsService } from 'src/app/Services/core/events/events.service';
import { CdkDrag, CdkDragDrop, CdkDragMove, moveItemInArray } from '@angular/cdk/drag-drop';
import { merge, Subscription } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-chapters',
  templateUrl: './chapters.component.html',
  styleUrls: ['./chapters.component.scss']
})
export class ChaptersComponent implements OnInit {
  @Output() chapterArrayOut = new EventEmitter();
  @ViewChild('scrollEl')
  scrollEl: ElementRef<HTMLElement>;

  @ViewChildren(CdkDrag)
  dragEls: QueryList<CdkDrag>;
  subs = new Subscription();
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  ngAfterViewInit() {
    const onMove$ = this.dragEls.changes.pipe(
      startWith(this.dragEls)
      , map((d: QueryList<CdkDrag>) => d.toArray())
      , map(dragels => dragels.map(drag => drag.moved))
      , switchMap(obs => merge(...obs))
      , tap(this.triggerScroll)
    );

    this.subs.add(onMove$.subscribe());

    const onDown$ = this.dragEls.changes.pipe(
      startWith(this.dragEls)
      , map((d: QueryList<CdkDrag>) => d.toArray())
      , map(dragels => dragels.map(drag => drag.ended))
      , switchMap(obs => merge(...obs))
      , tap(this.cancelScroll)
    );

    this.subs.add(onDown$.subscribe());
  }

  courseid: any;
  chapterList: any = [];
  searchText: string;
  p: number = 1;

  constructor(
    private activatedRoute: ActivatedRoute,
    private events: EventsService,
    private chapterservice: ChapterserviceService,
    private commonService: CommonService,
    private router: Router

  ) {

    this.activatedRoute.queryParams.subscribe(params => {
      this.courseid = params.courseid
    });

    this.events.subscribe(eventKeys.usercreated, () => {
      this.GetChapterList();
    })
  }

  ngOnInit(): void {
    this.GetChapterList();
  }


  GetChapterList() {
    this.commonService.presentSpinner();
    this.chapterservice.course_id = this.courseid;
    this.chapterservice.getChapterList().then(
      (res) => {
        if (res) {
          if (res.code == 200) {
            this.chapterList = res.chapterList;
            this.commonService.dismissSpinner();
          } else if (res.code == 400) {
            this.commonService.dismissSpinner();
            // this.commonService.presentErrorToast('', 'No records found');
          }
        } else {

        }

      }
    )
  }

  newArr: any = [];
  drop(event: CdkDragDrop<string[]>) {
    this.newArr = []
    moveItemInArray(this.chapterList, event.previousIndex, event.currentIndex);
    // this.pre = JSON.stringify(this.chapterList, null, ' ');
    this.chapterList.forEach(e => {
      this.newArr.push(e._id)
    });
  }
  private animationFrame: number | undefined;

  @bound
  public triggerScroll($event: CdkDragMove) {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
      this.animationFrame = undefined;
    }
    this.animationFrame = requestAnimationFrame(() => this.scroll($event));
  }
  @bound
  private cancelScroll() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
      this.animationFrame = undefined;
    }
  }

  speed: number = 5
  private scroll($event: CdkDragMove) {
    debugger
    const { y } = $event.pointerPosition;
    const baseEl = this.scrollEl.nativeElement;
    const box = baseEl.getBoundingClientRect();
    const scrollTop = baseEl.scrollTop;
    const top = box.top + - y;
    if (top > 0 && scrollTop !== 0) {
      const newScroll = scrollTop - this.speed * Math.exp(top / 500);
      baseEl.scrollTop = newScroll;
      this.animationFrame = requestAnimationFrame(() => this.scroll($event));
      return;
    }

    const bottom = y - box.bottom;
    if (bottom > 0 && scrollTop < box.bottom) {
      const newScroll = scrollTop + this.speed * Math.exp(bottom / 50);
      baseEl.scrollTop = newScroll;
      this.animationFrame = requestAnimationFrame(() => this.scroll($event));
    }
    if (bottom < 0) {
      const newScroll = scrollTop + this.speed * Math.exp(bottom / 50);
      baseEl.scrollTop = newScroll;
      this.animationFrame = requestAnimationFrame(() => this.scroll($event));
    }
  }

  ChangeOrder() {
    debugger
    this.commonService.presentSpinner();
    this.chapterservice.course_id = this.courseid;
    var postdata = {
      'chapterIds': this.newArr
    }
    this.chapterservice.ChangeOrder(postdata).then(
      (res) => {
        if (res) {
          if (res.code == 200) {
            this.newArr = []
            this.GetChapterList();
            $('.changeOrder_detail').modal('hide');
            this.commonService.dismissSpinner();
            this.commonService.presentSuccessToast('', res.message);
          } else if (res.code == 400) {
            this.newArr = []
            $('.changeOrder_detail').modal('hide');
            this.commonService.dismissSpinner();
          }
        } else {
          this.newArr = []
          $('.changeOrder_detail').modal('hide');
        }

      }
    )
  }
  // pre = this.newArr;
  chapterid: any;
  DeleteChapter(item, data) {

    if (data == 'open') {
      this.chapterid = item;
    } else if (data == 'confirm') {
      this.commonService.presentSpinner();
      this.chapterservice.DeleteChapter(item).then(
        (res: any) => {
          this.commonService.dismissSpinner();
          if (res && res.code === 200) {
            this.GetChapterList();
            $('#confirmodal').modal('hide');
            this.commonService.presentSuccessToast('', res.message);
          }
        }
      )
    }

  }
  ChangeUserStatus(event, id) {

    this.commonService.presentSpinner();
    this.chapterservice.chapterid = id;
    var data;
    if (event.srcElement.checked == true) {
      data = { "status": "Active" }
    } else {
      data = { "status": "Inactive" }
    }
    this.chapterservice.ChangeChapterStatus(data).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          this.commonService.dismissSpinner();
          if (res.code === 200) {
            this.GetChapterList();
            this.commonService.presentSuccessToast('', res.message);

          }
        } else {
          this.commonService.dismissSpinner();
        }
      }
    );

  }
  ChapterDetail(item) {

    // this.chapterservice.sendchaapterobj(item);
    this.router.navigate(['/admin/chapterdetail'], { queryParams: { chapterid: item._id } },);
  }

}
export function bound(target: Object, propKey: string | symbol) {
  var originalMethod = (target as any)[propKey] as Function;

  // Ensure the above type-assertion is valid at runtime.
  if (typeof originalMethod !== "function") throw new TypeError("@bound can only be used on methods.");

  if (typeof target === "function") {
    // Static method, bind to class (if target is of type "function", the method decorator was used on a static method).
    return {
      value: function () {
        return originalMethod.apply(target, arguments);
      }
    };
  } else if (typeof target === "object") {
    // Instance method, bind to instance on first invocation (as that is the only way to access an instance from a decorator).
    return {
      get: function () {
        // Create bound override on object instance. This will hide the original method on the prototype, and instead yield a bound version from the
        // instance itself. The original method will no longer be accessible. Inside a getter, 'this' will refer to the instance.
        var instance = this;

        Object.defineProperty(instance, propKey.toString(), {
          value: function () {
            // This is effectively a lightweight bind() that skips many (here unnecessary) checks found in native implementations.
            return originalMethod.apply(instance, arguments);
          }
        });

        // The first invocation (per instance) will return the bound method from here. Subsequent calls will never reach this point, due to the way
        // JavaScript runtimes look up properties on objects; the bound method, defined on the instance, will effectively hide it.
        return instance[propKey];
      }
    } as PropertyDescriptor;
  }
}