export const endpoint = {
    login: "/admin/login",
    adduser: "/admin/user",
    // userlist: "/admin/user",
    userlist: (page: string) => { return `/admin/user?page=` + page + `&limit=10` },
    // deleteuser: "admin/user/",
    deleteuser: (user_id: string) => { return `/admin/user/${user_id}` },
    addcourse: "/admin/course",
    GetUserallcourse: "/admin/course",
    allcourse: (page: string) => { return `/admin/course?page=` + page + `&limit=10` },
    deletecourse: (course_id: string) => { return `/admin/course/${course_id}` },
    edituser: (user_id: string) => { return `/admin/user/${user_id}` },
    editcourse: (courseid: string) => { return `/admin/course/${courseid}` },
    addchapter: "/admin/chapter",
    changepassword: "/admin/change-password",
    forgotpassword: "/admin/forgot-password",
    resetpassword: "/admin/reset-password",
    userchangepassword: (user_id: string) => { return `/admin/user/change-password/${user_id}` },
    getcoursebyid: (courseid: string) => { return `/admin/chapters/${courseid}` },
    changeorder: (courseid: string) => { return `/admin/chapter/change-order/${courseid}` },
    deletechapter: (chapterid: string) => { return `/admin/chapter/${chapterid}` },
    changeuserstatus: (userid: string) => { return `/admin/user/change-status/${userid}` },
    singleassessment: (chapterid: string) => { return `/admin/chapter/${chapterid}` },
    assigncourse: "/admin/course/assign",
    addassessment: "/admin/assessment",
    editassesment: (assesmentid: string) => { return `/admin/assessment/${assesmentid}` },
    deleteassessment: (assesmentid: string) => { return `/admin/assessment/${assesmentid}` },
    getchapterbyid: (chapterid: string) => { return `/admin/chapter/${chapterid}` },
    Editchapterbyid: (chapterid: string) => { return `/admin/chapter/${chapterid}` },
    addchaptervideo: (chapterid: string) => { return `/admin/video/${chapterid}` },
    asigncurselist: (userid: string) => { return `/admin/user/${userid}` },
    addresource: (chapterid: string) => { return `/admin/resource/${chapterid}` },
    deleteresource: (chapterid: string) => { return `/admin/resource/${chapterid}` },
    getresource: (chapterid: string) => { return `/admin/resource/${chapterid}` },
    getchaptervideo: (chapterid: string) => { return `/admin/video/${chapterid}` },
    coursechangestatus: (courseid: string) => { return `/admin/course/change-status/${courseid}` },
    chapterchangestatus: (chapterid: string) => { return `/admin/chapter/change-status/${chapterid}` },
    searchuser: "/admin/user",
    searchchapter: "/admin/course",
    deletevideo: (chapterid: string) => { return `/admin/video/${chapterid}` },
    editvideo: (chapterid: string) => { return `/admin/video/${chapterid}` },
    asigncoursetouser: (userid: string) => { return `/admin/assign-course/${userid}` },
    asigncoursechangestatus: (userid: string) => { return `/admin/course/change-assign-status/${userid}` },
    courseactive: "/admin/course-active",
    logout: "/admin/logout",
}