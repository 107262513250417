import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminComponent } from "./admin.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SubscribersComponent } from './subscribers/subscribers.component';
import { CoursesComponent } from './courses/courses.component';
import { AssessmentsComponent } from './assessments/assessments.component';
import { UsermodalComponent } from './modals/usermodal/usermodal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './filter.pipe';
import { CoursemodalComponent } from './modals/coursemodal/coursemodal.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AngularEditorModule } from "@kolkov/angular-editor";
import { ChaptersComponent } from './chapters/chapters.component';
import { ChaptermodalComponent } from './modals/chaptermodal/chaptermodal.component';
import { AuthguardGuard } from "src/app/authguard/authguard.guard";
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AsigncoursemodelComponent } from './modals/asigncoursemodel/asigncoursemodel.component';
import { AsigncoursedetailsComponent } from './asigncoursedetails/asigncoursedetails.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { NgxPaginationModule } from "ngx-pagination";
import { ChapterDetailsComponent } from './chapter-details/chapter-details.component';
import { AddQuestionModalComponent } from './modals/add-question-modal/add-question-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

const routes: Routes = [
    {
        path: '', component: AdminComponent, children: [
            { path: '', redirectTo: '/admin/subscribers', pathMatch: 'full', canActivate: [AuthguardGuard] },
            // { path: 'admin/dashboard', component: DashboardComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/subscribers', component: SubscribersComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/courses', component: CoursesComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/assessments', component: AssessmentsComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/chapter', component: ChaptersComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/asigncourse', component: AsigncoursedetailsComponent, canActivate: [AuthguardGuard] },
            { path: 'admin/chapterdetail', component: ChapterDetailsComponent, canActivate: [AuthguardGuard] },
            // { path: 'resetpassword', component: ForgotPasswordComponent }
        ]
    }
]

@NgModule({
    declarations: [
        AdminComponent,
        DashboardComponent,
        SubscribersComponent,
        CoursesComponent,
        AssessmentsComponent,
        UsermodalComponent,
        FilterPipe,
        CoursemodalComponent,
        ChaptersComponent,
        ChaptermodalComponent,
        ForgotPasswordComponent,
        AsigncoursemodelComponent,
        AsigncoursedetailsComponent,
        ChapterDetailsComponent,
        AddQuestionModalComponent,
    ],
    imports: [
        ReactiveFormsModule, FormsModule, CommonModule, CKEditorModule, AngularEditorModule, NgApexchartsModule, NgxPaginationModule, DragDropModule,
        RouterModule.forChild(routes)
    ],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AdminModule { }