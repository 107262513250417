import { Injectable } from '@angular/core';
import { endpoint } from '../constants/endpoints.constants';
import { CommonService } from './common/common.service';
import { EventsService } from './core/events/events.service';
import { HttpWrapperService } from './core/http/http-wrapper/http-wrapper.service';
import { StorageService } from './core/storage/storage.service';
import { storageKeys } from '../constants/storage-keys.constants';
import { eventKeys } from '../constants/event-keys.constants';
import { UserData } from '../components/admin/modals/usermodal/users.model';
import { Coursedata } from '../components/admin/modals/coursemodal/course.model';
import { HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  Uid: string;

  constructor(private httpWrapperService: HttpWrapperService,
    private commonService: CommonService,
    private storage: StorageService,
    private events: EventsService) { }

  async login(body: { email: string, password: string }) {
    return this.httpWrapperService.post(endpoint.login, body, { observe: 'response' }).map(
      (res: any) => {
        if (res) {
          if (res.status == 200) {
            this.handleLogin(res);
          }
          return res;
        }
      }
    ).toPromise()
      .catch((error) => {
        if (error.error.code == 422) {
          return error.error
        }
        // this.commonService.handleError(error);
      });
  }

  private handleLogin(loginResponse: any) {

    this.commonService.presentSuccessToast('Logged-in successfully!', '');
    this.storage.set(storageKeys.userDetails, loginResponse.body.admindata);
    this.storage.set(storageKeys.auth_token, loginResponse.body.token);
    this.storage.set(storageKeys.user_id, loginResponse.body.admindata._id);
    this.events.publish(eventKeys.loginSuccess, '');
  }


  async AddUser(userdata) {
    return this.httpWrapperService.post(endpoint.adduser, userdata).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.events.publish(eventKeys.usercreated, '');
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }

  async forgotpassword(email) {

    return this.httpWrapperService.get(endpoint.forgotpassword, email).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.events.publish(eventKeys.usercreated, '');
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }

  async RestPassword(userdata) {

    return this.httpWrapperService.post(endpoint.resetpassword, userdata).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            // this.events.publish(eventKeys.usercreated, '');
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }



  async getUserList(page) {

    return this.httpWrapperService.get(endpoint.userlist(page)).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.userList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async deleteUserList(user_id: string) {

    let payload = {}
    return this.httpWrapperService.delete(endpoint.deleteuser(user_id)).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }



  async EditUser(payload: any) {
    return this.httpWrapperService.put(endpoint.edituser(this.Uid), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      );
  }

  async ChangeUserStatus(status: any) {
    return this.httpWrapperService.put(endpoint.changeuserstatus(this.Uid), status).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      );
  }

  async AddCourse(coursedata: any) {

    // const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }) };
    return this.httpWrapperService.post(endpoint.addcourse, coursedata).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            this.events.publish(eventKeys.usercreated, '');
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          // this.commonService.handleError(error);
        }
      )
  }

  async ChangePassword(payload: any) {

    return this.httpWrapperService.put(endpoint.changepassword, payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 422) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async UserChangePassword(payload: any) {

    return this.httpWrapperService.put(endpoint.userchangepassword(this.Uid), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 422) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }

  async Logout() {

    // const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }) };
    return this.httpWrapperService.post(endpoint.logout).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          // this.commonService.handleError(error);
        }
      )
  }


  async UserSearchFilter(payload) {

    return this.httpWrapperService.get(endpoint.searchuser + '?search=' + payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {
            if (res.userList) {
            }
          } else { }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          if (error.error.code == 401) {
            return error.error
          }
          // this.commonService.handleError(error);
        }
      );
  }


  async Deleteasigncourse(id: any) {

    return this.httpWrapperService.delete(endpoint.asigncoursetouser(this.Uid) + '?courseId=' + id).map(
      (res) => {
        if (res) {
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      )
  }


  async ChangeAsignCourseStatus(payload) {

    return this.httpWrapperService.put(endpoint.asigncoursechangestatus(this.Uid), payload).map(
      (res) => {
        if (res) {
          if (res.code === 200) {

          }
          return res;
        }
      }
    ).toPromise()
      .catch(
        (error) => {
          this.commonService.handleError(error);
        }
      );
  }

}
