import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { eventKeys } from 'src/app/constants/event-keys.constants';
import { MustMatch } from 'src/app/constants/regex.constants';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { CommonService } from 'src/app/Services/common/common.service';
import { EventsService } from 'src/app/Services/core/events/events.service';
declare var $: any;
@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.scss']
})
export class SubscribersComponent implements OnInit {
  page = 1;
  count = 0;
  tableSize = 7;
  UserList: any = [];
  searchText: any;
  EditUserForm: FormGroup;
  ChangePasswordForm: FormGroup;
  limit: any;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private authenticate: AuthenticationService,
    private commonService: CommonService,
    private events: EventsService,
    private router: Router
  ) {

    this.events.subscribe(eventKeys.usercreated, () => {
      this.GetUserList();
    })
  }

  ngOnInit(): void {
    this.ChangePasswordForm = this.formBuilder.group({
      NewPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      // email: ['', [Validators.required, Validators.email]],
    },
      {
        validator: MustMatch('NewPassword', 'confirmPassword')
      }
    )

    this.EditUserForm = this.formBuilder.group({
      UserImg: ['', ''],
      Name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    })
    this.GetUserList();
  }

  get f() { return this.EditUserForm.controls; }
  get f1() { return this.ChangePasswordForm.controls; }

  onTableDataChange(event) {

    this.page = event;
    this.GetUserList();
  }
  // page: number = 0;
  pageData: any;
  GetUserList() {

    this.commonService.presentSpinner();
    // this.page = 1



    this.authenticate.getUserList(this.page).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code == 200) {
            this.UserList = [];
            this.UserList = res.userList;
            this.count = res.totalResult
            this.limit = res.limit;
            if (this.page == 1) {
              // var abc = this.page + "-" + this.page * 10;
              // this.pageData = this.page + "-" + this.page * 10;
              if (this.count > this.page * 10) {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
              } else {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
              }

            } else {
              if (this.count > this.page * 10) {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
              } else {
                this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
              }
              //   (this.page - 1) * 10 + 1 - this.page * 10
            }




          } else if (res.code == 401) {
            this.router.navigate(['/']);
          }
        } else {

        }

      }
    )
  }
  fileToUpload: File = null;
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  adminuserId: any;
  img: any;
  EditUser(items) {

    this.img = '';
    this.fileToUpload = null;
    this.adminuserId = items._id;
    this.img = items.profileImage;
    this.EditUserForm.patchValue({
      UserImage: items.profileImage,
      Name: items.name,
      email: items.email,
    });


  }

  UpdateUser() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.EditUserForm.invalid) {
      return;
    }
    this.commonService.presentSpinner();
    var frmData = new FormData();
    frmData.append("profileImage", this.fileToUpload);
    frmData.append("name", this.EditUserForm.value.Name);
    frmData.append("email", this.EditUserForm.value.email);
    this.authenticate.Uid = this.adminuserId;
    this.authenticate.EditUser(frmData).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          this.commonService.dismissSpinner();
          if (res.code === 200) {
            this.GetUserList();
            this.EditUserForm.reset();
            $('.edit_detail').modal('hide');
            this.commonService.presentSuccessToast('', res.message);

          }
        } else {
          this.commonService.dismissSpinner();
        }
      }
    );
  }

  userdeleteid: any;
  openUsermodal(id) {
    this.userdeleteid = id;
  }

  DeleteUser() {
    this.commonService.presentSpinner();
    this.authenticate.deleteUserList(this.userdeleteid).then(
      (res: any) => {
        this.commonService.dismissSpinner();
        if (res && res.code === 200) {
          this.GetUserList();
          $('#confirmodal').modal('hide');
          this.commonService.presentSuccessToast('', res.message);

        }
      }
    )
  }

  changepasswordid: any;
  changePassword(items, data) {


    if (data == 'open') {
      this.changepasswordid = items._id
    } else if (data == 'confirm') {
      this.submitted = true;
      // stop here if form is invalid
      if (this.ChangePasswordForm.invalid) {
        return;
      }
      this.authenticate.Uid = this.changepasswordid;
      let postdata = {
        newPassword: this.ChangePasswordForm.value.NewPassword,
        confirmPassword: this.ChangePasswordForm.value.confirmPassword
      }

      this.commonService.presentSpinner();
      this.authenticate.UserChangePassword(postdata).then(
        (res) => {
          if (res) {

            if (res.code == 200) {
              this.commonService.dismissSpinner();
              $('#changepass').modal('hide');
              this.ChangePasswordForm.reset();
              this.commonService.presentSuccessToast('', res.message);
            } else if (res.code == 422) {
              this.commonService.dismissSpinner();
              this.commonService.presentErrorToast('', res.message);
            }
          } else {

          }

        }
      )
    }




  }

  ChangeUserStatus(event, userid) {
    this.commonService.presentSpinner();
    this.authenticate.Uid = userid;
    var data;
    if (event.srcElement.checked == true) {
      data = { "status": "Active" }
    } else {
      data = { "status": "Inactive" }
    }
    this.authenticate.ChangeUserStatus(data).then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          this.commonService.dismissSpinner();
          if (res.code === 200) {
            this.GetUserList();
            this.commonService.presentSuccessToast('', res.message);

          }
        } else {
          this.commonService.dismissSpinner();
        }
      }
    );

  }

  AsignCourseDetails(userid) {
    this.router.navigate(['/admin/asigncourse'], { queryParams: { userid: userid } });

  }

  applyFilter(filterValue) {
    if (filterValue.length >= 3) {

      this.authenticate.UserSearchFilter(filterValue).then(
        (res) => {
          if (res) {
            if (res.code == 200) {
              this.UserList = [];
              this.UserList = res.userList;
              this.count = res.totalResult
              this.limit = res.limit;
              if (this.page == 1) {
                if (this.count > this.page * 10) {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
                } else {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
                }
              } else {
                if (this.count > this.page * 10) {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.page * 10;
                } else {
                  this.pageData = (this.page - 1) * 10 + 1 + "-" + this.count;
                }
                //   (this.page - 1) * 10 + 1 - this.page * 10
              }
            } else if (res.code == 401) {
              this.router.navigate(['/']);
            }
          } else {

          }

        }
      )
    } else {
      this.GetUserList();
    }

  }

}
