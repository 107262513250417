import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, RouterEvent } from '@angular/router';
import { CommonService } from 'src/app/Services/common/common.service';
import { EventsService } from 'src/app/Services/core/events/events.service';
import { CourseService } from 'src/app/Services/courseservice/course.service';
declare var $: any;

@Component({
  selector: 'app-asigncoursemodel',
  templateUrl: './asigncoursemodel.component.html',
  styleUrls: ['./asigncoursemodel.component.scss']
})
export class AsigncoursemodelComponent implements OnInit {
  courselist: any = [];
  AsignCourseForm: FormGroup;
  submitted = false;
  @Input() userid: any;
  constructor(
    private formBuilder: FormBuilder,
    private courseservice: CourseService,
    private commonService: CommonService,
    private router: Router,
    private events: EventsService,
  ) { }

  ngOnInit(): void {
    this.GetActiveCourseList();
    this.AsignCourseForm = this.formBuilder.group({
      coursename: ['', Validators.required],
      coursedate: ['', Validators.required],
    })
  }
  get f() { return this.AsignCourseForm.controls; }

  page: any;
  GetActiveCourseList() {

    this.commonService.presentSpinner();
    this.courseservice.getActiveCourseList().then(
      (res) => {
        this.commonService.dismissSpinner();
        if (res) {
          if (res.code == 200) {
            this.courselist = res.courseList;
          } else if (res.code == 401) {

          }
        } else {

        }

      }
    )
  }

  AsignCourse() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.AsignCourseForm.invalid) {
      return;
    }
    this.commonService.presentSpinner();
    let postdata = {
      'courseId': this.AsignCourseForm.value.coursename,
      'userId': this.userid,
      'expiryDate': this.AsignCourseForm.value.coursedate,
      "action": "add"
    }
    console.log(JSON.stringify(postdata));
    this.courseservice.Asigncourse(postdata).then(
      (res) => {

        if (res) {
          if (res.code == 200) {
            $('.add_detail').modal('hide');
            this.commonService.dismissSpinner();
            // this.tosterservice.success('message', 'title');
            this.submitted = false;
            this.commonService.presentSuccessToast('', res.message);
            this.AsignCourseForm.reset();
            this.AsignCourseForm['controls'].coursename.setValue('');
          } else if (res.code == 422) {
          }
        } else {
          this.commonService.dismissSpinner();
        }

      }
    )
  }
}
