import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexTitleSubtitle,
  ApexPlotOptions,
  ApexXAxis
} from "ng-apexcharts";
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  title: ApexTitleSubtitle;
  labels: string[];
  plotOptions: ApexPlotOptions;
  stroke: any; // ApexStroke;
  dataLabels: any; // ApexDataLabels;
  fill: ApexFill;
  tooltip: ApexTooltip;
};
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  constructor() {
    this.chartOptions = {
      series: [
        {
          name: "2020",
          type: "column",
          color:'#ff9c00',
          data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
        },
        {
          name: "2019",
          type: "line",
          color:'#1cbb8c',
          data: [23, 32, 27, 38, 27, 32, 27, 38, 22, 31, 21, 16]
        }
        
      ],
      
      chart: {
        height:280,
        type: "line",
        toolbar: {
            show: !1
        }
      },
     
      stroke: {
        width: [0, 3],
        curve: "smooth"
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "10%",
          distributed: false,
          rangeBarOverlap: true,
          rangeBarGroupRows: false,
        }
      },
      dataLabels: {
        enabled: !1
      },
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      
    };
  }

  ngOnInit(): void {
  }

}
